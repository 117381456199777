import { ExpandMore } from '@mui/icons-material';
import { Avatar, Box, Button, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import DataTable, { SortOrder, TableColumn } from 'react-data-table-component';
import { defaultPageSize, Direction, sortOrderToDirection } from '../../apis/common';
import {
    EnquirySortProperty,
    EnquiryStatus,
    EnquirySummary,
    searchEnquiries,
    UNASSIGNED_FLAG,
} from '../../apis/enquiry';
import EnquiryStatusChip from '../../components/EnquiryStatusChip';
import ErrorMessage from '../../components/ErrorMessage';
import FormattedDateTime from '../../components/FormattedDateTime';
import NoSearchResults from '../../components/NoSearchResults';
import PageLoading from '../../components/PageLoading';
import SortIcons from '../../components/SortIcons';
import useDebouncedValue from '../../hooks/useDebouncedValue';
import { isError, isLoading, isPending, isSuccess, useFetch } from '../../hooks/useFetch';
import { useOpenRow } from '../../hooks/useOpenRow';
import { getWorklistStyles } from '../../style/theme';
import { DATE_FRIENDLY, TIME_FRIENDLY } from '../../util/dateUtils';
import { firstCharsInString, firstNameInString } from '../../util/nameUtils';

type Props = {
    enquiryTypes: string[];
    enquiryStatuses: EnquiryStatus[];
    enquiryStartDate?: string;
    enquiryEndDate?: string;
    assigneesSelected?: string[];
};

const DEBOUNCE_MS = 500;

export default function List({
    enquiryTypes,
    enquiryStatuses,
    enquiryStartDate,
    enquiryEndDate,
    assigneesSelected,
}: Readonly<Props>) {
    const { rowClick, setUrl } = useOpenRow();
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [sortProperty, setSortProperty] = useState(EnquirySortProperty.ENQUIRY_DATE);
    const [direction, setDirection] = useState(Direction.DESC);

    const debouncedEnquiryTypes = useDebouncedValue(enquiryTypes, DEBOUNCE_MS);
    const debouncedEnquiryStatuses = useDebouncedValue(enquiryStatuses, DEBOUNCE_MS);
    const debouncedEnquiryStart = useDebouncedValue(enquiryStartDate, DEBOUNCE_MS);
    const debouncedEnquiryEnd = useDebouncedValue(enquiryEndDate, DEBOUNCE_MS);
    const debouncedAssignees = useDebouncedValue(assigneesSelected, DEBOUNCE_MS);

    const state = useFetch(
        () =>
            searchEnquiries({
                page: page - 1,
                pageSize,
                enquirySortProperty: sortProperty,
                direction,
                enquiryTypes: debouncedEnquiryTypes,
                enquiryStatuses: debouncedEnquiryStatuses,
                enquiryDateFrom: debouncedEnquiryStart,
                enquiryDateTo: debouncedEnquiryEnd,
                assigneeIdentifiers: debouncedAssignees?.filter((assignee) => assignee !== UNASSIGNED_FLAG),
                includeNullAssignee: debouncedAssignees?.includes(UNASSIGNED_FLAG),
            }),
        [
            page,
            pageSize,
            sortProperty,
            direction,
            debouncedEnquiryTypes,
            debouncedEnquiryStatuses,
            debouncedEnquiryTypes,
            debouncedEnquiryStart,
            debouncedEnquiryEnd,
            debouncedAssignees,
        ]
    );

    const handleSort = (column: TableColumn<EnquirySummary>, sortOrder: SortOrder) => {
        setSortProperty(column.id as EnquirySortProperty);
        setDirection(sortOrderToDirection(sortOrder));
        setPage(1);
    };

    if (isError(state)) {
        return <ErrorMessage />;
    }

    return (
        <DataTable
            data={isSuccess(state) ? state.value.records : []}
            columns={columns}
            onRowClicked={(row: EnquirySummary, e: React.MouseEvent) => {
                rowClick('/enquiry/' + row.uuid, e);
            }}
            onRowMouseEnter={(row: EnquirySummary) => {
                setUrl('/enquiry/' + row.uuid);
            }}
            pointerOnHover={true}
            highlightOnHover={true}
            pagination
            paginationServer
            onSort={handleSort}
            defaultSortAsc={false}
            defaultSortFieldId={EnquirySortProperty.NAME}
            sortIcon={<SortIcons />}
            sortServer
            onChangePage={setPage}
            onChangeRowsPerPage={setPageSize}
            paginationPerPage={pageSize}
            paginationDefaultPage={page}
            paginationTotalRows={isSuccess(state) ? state.value.totalRecords : 0}
            progressPending={isLoading(state) || isPending(state)}
            progressComponent={<PageLoading />}
            noDataComponent={
                <Box width='100%'>
                    <NoSearchResults />
                </Box>
            }
            customStyles={{
                ...getWorklistStyles(sortProperty),
                rows: {
                    style: {
                        height: 65,
                    },
                },
            }}
        />
    );
}

const columns: TableColumn<EnquirySummary>[] = [
    {
        id: EnquirySortProperty.TYPE,
        name: 'Type',
        sortable: true,
        cell: (row) => row.enquiryType,
    },
    {
        id: EnquirySortProperty.NAME,
        name: 'Name',
        sortable: true,
        selector: (row) => row.clientName,
    },
    {
        id: EnquirySortProperty.ENQUIRY_DATE,
        name: 'Enquiry date',
        sortable: true,
        cell: (row) => (
            <FormattedDateTime date={row.createdDate} dateFormat={DATE_FRIENDLY} timeFormat={TIME_FRIENDLY} />
        ),
    },
    {
        id: EnquirySortProperty.LAST_CONTACT_DATE_TIME,
        name: 'Last contact date',
        sortable: true,
        cell: (row) => RenderLatestContactActivity(row.lastContactDateTime),
    },
    {
        id: EnquirySortProperty.STATUS,
        name: 'Status',
        sortable: true,
        cell: (row) => <EnquiryStatusChip status={row.status} />,
    },
    {
        name: 'Assigned to',
        cell: (row) => RenderAssignee(row.assigneeIdentifier, row.assigneeName),
    },
];

const RenderLatestContactActivity = (lastContactDateTime?: string) => {
    if (!lastContactDateTime) {
        return (
            <Box>
                <Typography variant='caption'>-</Typography>
            </Box>
        );
    }
    return <FormattedDateTime date={lastContactDateTime} dateFormat={DATE_FRIENDLY} timeFormat={TIME_FRIENDLY} />;
};

const RenderAssignee = (assigneeIdentifier?: string, assigneeName?: string) => {
    const theme = useTheme();
    const [hovered, setHovered] = useState<string>('none');

    if (!assigneeIdentifier || !assigneeName) {
        return (
            <Button
                variant='text'
                onMouseEnter={() => setHovered('block')}
                onMouseLeave={() => setHovered('none')}
                sx={{ maxWidth: 140, whiteSpace: 'nowrap' }}
                endIcon={<ExpandMore sx={{ display: hovered }} />}
            >
                Unassigned
            </Button>
        );
    }

    return (
        <Button
            variant='text'
            sx={{ maxWidth: 140, whiteSpace: 'nowrap' }}
            startIcon={
                <Avatar
                    sx={{ bgcolor: theme.palette.primary.main, width: 25, height: 25, whiteSpace: 'nowrap' }}
                    style={{ fontSize: 12 }}
                >
                    {firstCharsInString(assigneeName)}
                </Avatar>
            }
        >
            {firstNameInString(assigneeName)}
        </Button>
    );
};
