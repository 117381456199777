import { Box, Typography } from '@mui/material';
import { brown, grey, red } from '@mui/material/colors';
import { Cover, CoverChargeType, CoverQuoteOutcome } from '../../../apis/quotes';

type Props = {
    cover: Cover;
};

export default function CoverRow({ cover }: Readonly<Props>) {
    const sumInsured = cover.coverageAmount;
    const annualPremium = cover.coverOptions[0].coverCharges
        .filter((charge) => [CoverChargeType.GST, CoverChargeType.PREMIUM].includes(charge.type))
        .reduce((a, v) => a + v.amount, 0);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                px: 2,
                py: 1,
                backgroundColor: cover.outcome === CoverQuoteOutcome.UNAVAILABLE ? red[50] : 'inherit',
                borderBottomColor: grey[300],
                borderBottomWidth: '1px',
                borderBottomStyle: 'solid',
            }}
        >
            <Box flexGrow={1}>
                <Typography variant='caption'>{cover.productCoverName}</Typography>
            </Box>
            {cover.outcome === CoverQuoteOutcome.SUCCESS && (
                <Box width={200}>
                    <Typography variant='caption'>
                        {sumInsured > 0 ? currencyFormatter.format(sumInsured) : '-'}
                    </Typography>
                </Box>
            )}
            {cover.outcome === CoverQuoteOutcome.UNAVAILABLE && (
                <Box width={200} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{ textDecoration: 'line-through' }} variant='caption'>
                        {currencyFormatter.format(sumInsured)}
                    </Typography>
                    <Typography variant='caption' color={brown[700]}>
                        Cover unavailable
                    </Typography>
                </Box>
            )}
            <Box width={200} textAlign='right'>
                <Typography variant='caption'>{currencyFormatter.format(annualPremium)}</Typography>
            </Box>
        </Box>
    );
}

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
