import axios from 'axios';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { UseAuthenticator } from '@aws-amplify/ui-react';
import SessionTimer from '../../components/SessionTimer';
import { axiosRequestInterceptor } from '../../interceptors/auth';
import Layout from '../../layout';
import { buildRoutesAndPages } from './appRoutesBuilder';
import { useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/reducer/Hooks';
import useLoadInitialData, { InitialData } from './useLoadInitialData';
import PageLoading from '../../components/PageLoading';
import { isSuccess } from '../../hooks/useFetch';

interface LoadInititialStateProps {
    signOut?: UseAuthenticator['signOut'];
}

const LoadInititialState = ({ signOut }: LoadInititialStateProps) => {
    const dispatch = useAppDispatch();

    axios.interceptors.request.use(
        (config) => axiosRequestInterceptor(config, dispatch),
        (e) => Promise.reject(e)
    );
    const initialDataState = useLoadInitialData();
    if (!isSuccess(initialDataState)) {
        return <PageLoading />;
    }

    return <AppPage signOut={signOut} initialData={initialDataState.value} />;
};

type AppPageProps = LoadInititialStateProps & {
    initialData: InitialData;
};

const AppPage = ({ signOut, initialData }: AppPageProps) => {
    const { permissions } = useAppSelector((state) => state.UserSessionReducer);

    const allowBack = [/^\/invoices\/.+/, /^\/enquiries\/.+/, /^\/clients\/.+/, /^\/users\/.+/];
    const { routes, pages } = useMemo(
        () => buildRoutesAndPages({ permissions, initialData }),
        [permissions, initialData]
    );

    const router = createBrowserRouter([
        {
            path: '/',
            element: <Layout pages={pages} allowBack={allowBack} signOut={signOut} />,
            children: routes,
        },
    ]);

    return (
        <>
            <SessionTimer signOut={signOut} />
            <RouterProvider router={router} />
        </>
    );
};

export default LoadInititialState;
