import axios from 'axios';
import { Invoice } from './invoice';

export const voidRenewal = async (invoiceUuid: string, note: string): Promise<Invoice> => {
    return await axios
        .delete(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/renewal/${invoiceUuid}`, { data: { note } })
        .then(({ data }) => data);
};

export const sendRenewalToCustomer = async (invoiceUuid: string): Promise<Invoice> => {
    return await axios
        .post(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/renewal/${invoiceUuid}/_action/send-to-customer`)
        .then(({ data }) => data);
};
