import { Box, styled, Typography } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import { PolicyVersionPreview } from '../../../apis/quotes';

type Props = {
    policyVersionPreview: PolicyVersionPreview;
};

export default function ProRataAdjustmentRow({ policyVersionPreview }: Readonly<Props>) {
    const isIncrease = policyVersionPreview.proRataPremiumDifference > 0;
    const isDecrease = policyVersionPreview.proRataPremiumDifference < 0;

    if (isIncrease || isDecrease) {
        const diffMsg = isIncrease ? 'Increase' : 'Decrease';
        return (
            <ProRataRow>
                <Box>
                    <Typography variant='subtitle2'>{diffMsg} in premium for remaining term</Typography>
                    <Typography variant='caption'>
                        Pro-rata adjustment between effective date and policy end date.
                    </Typography>
                </Box>
                <Box>
                    <Typography variant='h6' textAlign='right'>
                        {currencyFormatter.format(Math.abs(policyVersionPreview.proRataPremiumDifference))}
                    </Typography>
                    <Typography variant='caption'>{diffMsg}</Typography>
                </Box>
            </ProRataRow>
        );
    }

    return (
        <ProRataRow>
            <Box>
                <Typography variant='subtitle2'>No change in premium for remaining term</Typography>
                <Typography variant='caption'>
                    Pro-rata adjustment between effective date and policy end date.
                </Typography>
            </Box>
            <Box>
                <Typography variant='h6' textAlign='right'>
                    {currencyFormatter.format(0)}
                </Typography>
                <Typography variant='caption'>(No change)</Typography>
            </Box>
        </ProRataRow>
    );
}

const ProRataRow = styled(Box)(({ theme }) => ({
    border: grey[300],
    borderWidth: '0',
    borderTopWidth: '1px',
    borderStyle: 'solid',
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    alignItems: 'center',
    backgroundColor: blue['50'],
    borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
}));

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
