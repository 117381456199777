import { toNumber } from 'lodash';
import { Invoice, Policy, Portfolio, SaveInvoiceError } from '../../apis/invoice';
import moment, { Moment } from 'moment';
import * as yup from 'yup';
import { DATE_SERVER_FORMAT, invalidDate } from '../../util/dateUtils';
import { AxiosError } from 'axios';

export const tallyPremiums = (policies: Policy[]): number => {
    let total = 0;
    policies?.forEach((p) => {
        if (isFinite(p.currentPolicyVersion.annualPremiums) && p.currentPolicyVersion.annualPremiums > 0) {
            total += toNumber(p.currentPolicyVersion.annualPremiums);
        }
    });
    return total;
};

export const getBaseInvoiceRequest = (data: BaseInvoiceFormFields): Invoice => {
    return {
        number: data.invoiceNumber,
        portfolio: {
            policies: data.policies.map((datum) => {
                return {
                    ...datum,
                    currentPolicyVersion: {
                        ...datum.currentPolicyVersion,
                        effectiveDate: moment(datum.currentPolicyVersion.anniversaryDate).format(DATE_SERVER_FORMAT),
                        anniversaryDate: moment(datum.currentPolicyVersion.anniversaryDate).format(DATE_SERVER_FORMAT),
                        endDate: moment(datum.currentPolicyVersion.anniversaryDate)
                            .add(1, 'year')
                            .subtract(1, 'day')
                            .format(DATE_SERVER_FORMAT),
                    },
                };
            }),
        } as Portfolio,
    } as Invoice;
};

type CreateInvoiceError = {
    error: string;
};

export const getFriendlyErrorMsg = (e: AxiosError<CreateInvoiceError>) => {
    if (e.response && e.response.status === 400) {
        if (e.response.data?.error === SaveInvoiceError.NON_CONTIGUOUS_POLICY_DATES) {
            return 'Policy dates are not contiguous. Please correct the policy dates and try saving the invoice again.';
        } else if (e.response.data?.error === SaveInvoiceError.START_DATE_AFTER_END_DATE) {
            return 'Policy end date is before start date. Please correct the policy dates and try saving the invoice again.';
        } else if (e.response.data?.error === SaveInvoiceError.END_DATE_BEFORE_TODAY) {
            return 'Policy end date is before today. Please correct the policy dates and try saving the invoice again.';
        }
    }

    return 'Something went wrong. Please try saving your invoice again or contact Simfuni for support.';
};

export type BaseInvoiceFormFields = {
    policies: Policy[];
    invoiceNumber: string;
};

type BaseSchemaOptions = {
    minStartDate: {
        value: Moment;
        msg: string;
    };
};

export const baseSchema = (options?: BaseSchemaOptions) =>
    yup.object({
        invoiceNumber: yup.string().required('Invoice number required'),
        policies: yup.array().of(
            yup.object({
                number: yup
                    .string()
                    .required('Number required')
                    .test('policyNameUnique', 'Must be unique', (value, context) => {
                        const allPolicies = (context as unknown as FromContext<BaseInvoiceFormFields>).from[1].value
                            .policies;
                        return allPolicies.filter((p: Policy) => p.number === value).length === 1;
                    }),
                classCode: yup.string().required('Classcode required'),
                currentPolicyVersion: yup.object({
                    annualPremiums: yup
                        .number()
                        .required('Premiums required')
                        .positive('Positive value required')
                        .typeError('Number required'),

                    anniversaryDate: yup
                        .date()
                        .typeError('Start date required')
                        .required('Start date required')
                        .test('checkMinStartDate', options?.minStartDate.msg ?? '', (value) => {
                            if (!options?.minStartDate) {
                                return true;
                            }

                            return !!(
                                value &&
                                !invalidDate(value) &&
                                moment(value).endOf('day').isSameOrAfter(options?.minStartDate.value.startOf('day'))
                            );
                        }),
                }),
                provider: yup.string().required('Provider required'),
            })
        ),
    });

type FromContext<T> = {
    from: { value: T }[];
};
