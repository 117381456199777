import { Check, Edit } from '@mui/icons-material';
import { Box, Button, Collapse, Paper, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import {
    Control,
    FieldArrayWithId,
    FieldErrors,
    UseFieldArrayRemove,
    UseFormRegister,
    UseFormSetValue,
    UseFormTrigger,
    useWatch,
} from 'react-hook-form';
import BeneficiaryFields from './BeneficiaryFields';
import { itemSchema, QuoteFields } from '../common';
import CoverFields from './CoverFields';
import ItemFields from './ItemFields';
import RemoveChild from './RemoveChild';

type Props = {
    control: Control<QuoteFields>;
    register: UseFormRegister<QuoteFields>;
    errors: FieldErrors<QuoteFields>;
    setValue: UseFormSetValue<QuoteFields>;
    trigger: UseFormTrigger<QuoteFields>;
    itemIndex: number;
    remove?: UseFieldArrayRemove;
    initialValue: FieldArrayWithId<QuoteFields, `items`, 'id'>;
    handleQuestionnaireUpdate: () => void;
};

export default function EditItem({
    control,
    itemIndex,
    register,
    errors,
    setValue,
    trigger,
    remove,
    initialValue,
    handleQuestionnaireUpdate,
}: Readonly<Props>) {
    const [editMode, setEditMode] = useState(!itemSchema.isValidSync(initialValue));
    const itemName = useWatch({
        control,
        name: `items.${itemIndex}.name`,
    });
    const covers = useWatch({
        control,
        name: `items.${itemIndex}.covers`,
    });
    const questionnaire = useWatch({
        control,
        name: `items.${itemIndex}.questionnaire`,
    });
    const error = errors.items?.[itemIndex];
    const questionnaireError = Object.values(questionnaire).find((formField) => formField.errorMsg);

    useEffect(() => {
        if (error || questionnaireError) {
            setEditMode(true);
        }
    }, [error, questionnaireError]);

    return (
        <Paper
            variant='outlined'
            sx={{
                background: grey['50'],
                py: 3,
                px: 2,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            {editMode ? (
                <Box>
                    <Typography variant='h2' component='p'>
                        {itemName || `Item ${itemIndex + 1}`}
                    </Typography>
                </Box>
            ) : (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ maxWidth: '500px' }}>
                        <Typography variant='h2' component='p' pb={0}>
                            {itemName || `Item ${itemIndex + 1}`}
                        </Typography>
                        <Typography variant='caption'>
                            {covers
                                .filter((cover) => cover.coverageAmount)
                                .map((cover) => cover.productCoverName)
                                .join(', ') || 'No covers selected'}
                        </Typography>
                    </Box>
                    <Button variant='text' startIcon={<Edit />} onClick={() => setEditMode(true)}>
                        Edit
                    </Button>
                </Box>
            )}
            <Collapse in={editMode}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                    <ItemFields
                        control={control}
                        register={register}
                        errors={errors}
                        itemIndex={itemIndex}
                        handleQuestionnaireUpdate={handleQuestionnaireUpdate}
                    />
                    <CoverFields
                        control={control}
                        register={register}
                        errors={errors}
                        itemIndex={itemIndex}
                        setValue={setValue}
                        trigger={trigger}
                    />
                    <BeneficiaryFields control={control} register={register} errors={errors} itemIndex={itemIndex} />
                </Box>
            </Collapse>

            {editMode && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: remove ? 'space-between' : 'flex-end',
                        alignItems: 'flex-end',
                        mt: 4,
                    }}
                >
                    {remove && <RemoveChild remove={remove} index={itemIndex} childDescription='item' />}
                    <Button endIcon={<Check />} variant='contained' onClick={() => setEditMode(false)}>
                        Close
                    </Button>
                </Box>
            )}
        </Paper>
    );
}
