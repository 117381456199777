import { Search } from '@mui/icons-material';
import {
    Avatar,
    InputAdornment,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    TextField,
    useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { isError, upperFirst } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { UNASSIGNED_FLAG } from '../../../apis/enquiry';
import { Permission, searchUsersByNameWithPermission, UserSearchResult } from '../../../apis/users';
import ErrorMessage from '../../../components/ErrorMessage';
import useDebouncedValue from '../../../hooks/useDebouncedValue';
import { isSuccess, useFetch } from '../../../hooks/useFetch';
import { getMenuPropsStyle } from '../../../style/theme';
import { firstCharsInString } from '../../../util/nameUtils';

type Props = {
    assigneesSelected: string[];
    setAssigneesSelected: (assigneeIdentifiers: string[]) => void;
};
const DEBOUNCE_MS = 500;

export default function EnquiryAssigneeSelect({ assigneesSelected, setAssigneesSelected }: Readonly<Props>) {
    const theme = useTheme();
    const searchBar = useRef<HTMLInputElement>(null);
    const [assigneeSearchString, setAssigneeSearchString] = useState<string | undefined>('');
    const [assigneeSearchResult, setAssigneeSearchResult] = useState<UserSearchResult[]>([]);

    const debouncedAssigneeSearchString = useDebouncedValue(assigneeSearchString, DEBOUNCE_MS);

    const state = useFetch(
        () =>
            searchUsersByNameWithPermission({
                page: 0,
                pageSize: 20,
                permission: Permission.ENQUIRY_UPDATE,
                searchString: debouncedAssigneeSearchString,
            }),
        [debouncedAssigneeSearchString]
    );

    useEffect(() => {
        if (isSuccess(state)) {
            setAssigneeSearchResult(state.value.records);
        }
    }, [state]);

    const handleUserSelectChange = (event: SelectChangeEvent<typeof assigneesSelected>) => {
        const {
            target: { value },
        } = event;
        const selected = (typeof value === 'string' ? value.split(',') : value) as string[];
        setAssigneesSelected(selected);
    };

    const onChangeExpression = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value.toString();
        setAssigneeSearchString(value);
    };

    if (isError(state)) {
        return <ErrorMessage />;
    }

    return (
        <Select
            multiple
            displayEmpty={true}
            defaultValue={assigneesSelected}
            value={assigneesSelected}
            onChange={handleUserSelectChange}
            input={<OutlinedInput id='enquiry-assignee-multiple-checkbox' />}
            renderValue={(selected: string[]) => renderValues(selected, assigneeSearchResult, getFriendlyUserName)}
            MenuProps={MenuProps}
        >
            <MenuItem
                value={''}
                style={{ backgroundColor: grey[100] }}
                sx={{ p: 2 }}
                onKeyDown={(e) => {
                    e.stopPropagation();
                }}
                onClickCapture={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                <TextField
                    inputRef={searchBar}
                    value={assigneeSearchString}
                    placeholder='Search'
                    onChange={onChangeExpression}
                    fullWidth
                    size='small'
                    style={{ backgroundColor: grey[100] }}
                    InputProps={{
                        style: { backgroundColor: 'white' },
                        startAdornment: (
                            <InputAdornment position='start'>
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
            </MenuItem>

            <MenuItem value={UNASSIGNED_FLAG} sx={{ height: 50, px: 3 }}>
                <ListItemText primary={UNASSIGNED_FLAG} />
            </MenuItem>
            {assigneeSearchResult.map((user: UserSearchResult) => (
                <MenuItem key={user.uuid} value={user.username} sx={{ height: 50, px: 3 }}>
                    <Avatar
                        sx={{ bgcolor: theme.palette.primary.main, width: 25, height: 25, whiteSpace: 'nowrap', mr: 2 }}
                        style={{ fontSize: 12 }}
                    >
                        {firstCharsInString(user.name)}
                    </Avatar>
                    <ListItemText primary={user.name} />
                </MenuItem>
            ))}
        </Select>
    );
}

const renderValues = (
    selected: string[],
    values: UserSearchResult[],
    transform: (t: string, values: UserSearchResult[]) => string
) => {
    if (
        selected.length === 0 ||
        (selected.includes(UNASSIGNED_FLAG) &&
            selected.filter((selection) => selection !== UNASSIGNED_FLAG).length === values.length)
    ) {
        return 'Any';
    }

    return selected.map((userIdentifier: string) => transform(userIdentifier, values)).join(', ');
};

const getFriendlyUserName = (userIdentifier: string, values: UserSearchResult[]): string => {
    if (userIdentifier === UNASSIGNED_FLAG) {
        return UNASSIGNED_FLAG;
    }
    const userFoundIndex = values.findIndex((user) => (user.uuid = userIdentifier));
    return upperFirst(userFoundIndex > -1 ? values[userFoundIndex].name.toLowerCase().replace('_', ' ') : '');
};

const MenuProps = {
    ...getMenuPropsStyle(),
    MenuListProps: {
        style: {
            paddingTop: 0,
        },
    },
};
