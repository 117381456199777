import { UseAuthenticator } from '@aws-amplify/ui-react';
import { SvgIconComponent } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Auth, Hub } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { Outlet, ScrollRestoration, useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../store/Store';
import { BREAKPOINT } from '../store/reducer/BreakpointReducer';
import { clearClientLeadSummaryState } from '../store/reducer/ClientLeadReducer';
import { clearConfig } from '../store/reducer/ConfigReducer';
import { clearFederatedSsoConfigurationState } from '../store/reducer/FederatedSsoConfigurationReducer';
import { useAppDispatch, useAppSelector } from '../store/reducer/Hooks';
import { clearInvoiceDetailsState } from '../store/reducer/InvoiceDetailsReducer';
import { clearLoanState } from '../store/reducer/LoanReducer';
import { clearSellerConfigSate } from '../store/reducer/SellerConfigReducer';
import { clearUserSession } from '../store/reducer/UserSessionReducer';
import Footer from './Footer';
import MobileLayout from './MobileLayout';
import StandardLayout from './StandardLayout';

type LayoutProps = {
    pages: Page[];
    allowBack: RegExp[];
    signOut?: UseAuthenticator['signOut'];
};

export default ({ pages, allowBack, signOut }: LayoutProps) => {
    const [selectedPage, setSelectedPage] = useState(PageType.Dashboard);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        pages.forEach((page) => {
            const match = page.routeRegexes.some((regex) => regex.test(location.pathname));
            if (match) {
                setSelectedPage(page.type);
            }
        });
    }, [location, pages]);

    useEffect(() => {
        return Hub.listen('auth', (data) => {
            if (data.payload.event === 'signOut') {
                dispatch(clearUserSession());
                dispatch(clearConfig());
                dispatch(clearInvoiceDetailsState());
                dispatch(clearLoanState());
                dispatch(clearClientLeadSummaryState());
                dispatch(clearSellerConfigSate());
                dispatch(clearFederatedSsoConfigurationState());
                navigate('/');
            }
        });
    });

    const logout = () => {
        if (signOut) {
            signOut();
        } else {
            Auth.signOut();
        }
    };

    const handlePageClick = (page: Page) => {
        setSelectedPage(page.type);
        navigate(page.goto);
    };

    return (
        <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <ScrollRestoration />
            <Box sx={{ flex: 1 }}>
                <Layout
                    selectedPage={selectedPage}
                    handlePageClick={handlePageClick}
                    logout={logout}
                    pages={pages}
                    allowBack={allowBack}
                >
                    <Outlet />
                </Layout>
            </Box>
            <Footer />
        </Box>
    );
};

type InnerLayoutProps = LayoutProps & {
    children: React.ReactNode;
    selectedPage: PageType;
    handlePageClick: (page: Page) => void;
    logout: () => void;
};

const Layout = ({ selectedPage, handlePageClick, logout, children, pages, allowBack }: InnerLayoutProps) => {
    const { up } = useAppSelector((state: RootState) => state.BreakpointReducer);
    if (up(BREAKPOINT.sm)) {
        return (
            <StandardLayout
                selectedPage={selectedPage}
                handlePageClick={handlePageClick}
                logout={logout}
                pages={pages}
                allowBack={allowBack}
            >
                {children}
            </StandardLayout>
        );
    }

    return (
        <MobileLayout
            selectedPage={selectedPage}
            handlePageClick={handlePageClick}
            logout={logout}
            pages={pages}
            allowBack={allowBack}
        >
            {children}
        </MobileLayout>
    );
};

export enum PageType {
    Dashboard,
    Invoice,
    Client,
    User,
    Report,
    MatchClientLeads,
    Arrears,
    Cancellations,
    Enquiry,
}

export type Page = {
    type: PageType;
    goto: string;
    routeRegexes: RegExp[];
    name: React.ReactNode;
    icon: SvgIconComponent;
    element?: React.ReactNode;
};
