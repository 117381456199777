import { Box, styled, Typography } from '@mui/material';
import ProRataAdjustmentRow from './ProRataAdjustmentRow';
import { grey } from '@mui/material/colors';
import { Policy } from '../../../apis/invoice';
import { PolicyVersionPreview } from '../../../apis/quotes';

type Props = {
    policy: Policy;
    policyVersionPreview: PolicyVersionPreview;
};

export default function PremiumImpact({ policy, policyVersionPreview }: Readonly<Props>) {
    return (
        <Box>
            <Typography variant='h2' pb={0} mb={2}>
                Premium impact
            </Typography>
            <DescriptionTable>
                <DescriptionHeader>
                    <Typography variant='subtitle2'>Description</Typography>
                    <Typography variant='subtitle2'>Amount</Typography>
                </DescriptionHeader>
                <DescriptionRow>
                    <Typography variant='caption'>Current annual premium</Typography>
                    <Typography variant='caption'>
                        {currencyFormatter.format(policy.currentPolicyVersion.annualPremiums)}
                    </Typography>
                </DescriptionRow>
                <DescriptionRow>
                    <Typography variant='caption'>New annual premium</Typography>
                    <Typography variant='caption'>
                        {currencyFormatter.format(policyVersionPreview.totalAnnualPremium)}
                    </Typography>
                </DescriptionRow>
                <ProRataAdjustmentRow policyVersionPreview={policyVersionPreview} />
            </DescriptionTable>
        </Box>
    );
}

const DescriptionTable = styled(Box)(({ theme }) => ({
    border: grey[300],
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: theme.shape.borderRadius + 'px',
}));

const DescriptionHeader = styled(Box)(({ theme }) => ({
    borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    backgroundColor: grey[50],
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}));

const DescriptionRow = styled(Box)(({ theme }) => ({
    border: grey[300],
    borderWidth: '0',
    borderTopWidth: '1px',
    borderStyle: 'solid',
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}));

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
