import { useState } from 'react';
import { EnquiryStatus, EnquiryType } from '../../apis/enquiry';
import { Box, FormControl, FormLabel, Paper } from '@mui/material';
import EnquiryAssigneeSelect from './selectFilters/EnquiryAssigneeSelect';
import EnquiryTypeSelect from './selectFilters/EnquiryTypeSelect';
import EnquiryStatusSelect from './selectFilters/EnquiryStatusSelect';
import EnquiryCreateDateSelect from './selectFilters/EnquiryCreateDateSelect';
import List from './List';

type Props = {
    data: EnquiryType[];
};

export default function EnquiryPageBody({ data }: Readonly<Props>) {
    const [enquiryStatuses, setEnquiryStatuses] = useState<EnquiryStatus[]>([
        EnquiryStatus.NEW,
        EnquiryStatus.IN_PROGRESS,
        EnquiryStatus.ON_HOLD,
        EnquiryStatus.CLOSED,
        EnquiryStatus.SPAM,
    ]);
    const [enquiryTypes, setEnquiryTypes] = useState<string[]>([]);
    const [enquiryStartDate, setEnquiryStartDate] = useState<string>('');
    const [enquiryEndDate, setEnquiryEndDate] = useState<string>('');
    const [assigneesSelected, setAssigneesSelected] = useState<string[]>([]);

    return (
        <Paper variant={'flat'} sx={{ py: 3, px: 2, maxWidth: 1500 }}>
            <Box sx={{ display: 'flex', gap: 3, pb: 4 }}>
                <FormControl size='small' sx={{ flex: 2, minWidth: 200 }}>
                    <FormLabel htmlFor='enquiry-assignee-multiple-checkbox' sx={{ mb: 1, whiteSpace: 'nowrap' }}>
                        Assigned to
                    </FormLabel>
                    <EnquiryAssigneeSelect
                        assigneesSelected={assigneesSelected}
                        setAssigneesSelected={setAssigneesSelected}
                    />
                </FormControl>
                <FormControl size='small' sx={{ flex: 2, minWidth: 150 }}>
                    <FormLabel htmlFor='enquiry-type-multiple-checkbox' sx={{ mb: 1, whiteSpace: 'nowrap' }}>
                        Type
                    </FormLabel>
                    <EnquiryTypeSelect
                        availableEnquiryTypes={data}
                        enquiryTypes={enquiryTypes}
                        setEnquiryTypes={setEnquiryTypes}
                    />
                </FormControl>
                <FormControl size='small' sx={{ flex: 3, minWidth: 150 }}>
                    <FormLabel htmlFor='enquiry-status-multiple-checkbox' sx={{ mb: 1, whiteSpace: 'nowrap' }}>
                        Status
                    </FormLabel>
                    <EnquiryStatusSelect enquiryStatuses={enquiryStatuses} setEnquiryStatuses={setEnquiryStatuses} />
                </FormControl>
                <FormControl size='small' sx={{ flex: 3, minWidth: 400 }}>
                    <FormLabel sx={{ mb: 1, whiteSpace: 'nowrap' }}>Date created</FormLabel>
                    <EnquiryCreateDateSelect
                        enquiryStartDate={enquiryStartDate}
                        setEnquiryStartDate={setEnquiryStartDate}
                        enquiryEndDate={enquiryEndDate}
                        setEnquiryEndDate={setEnquiryEndDate}
                    />
                </FormControl>
            </Box>
            <List
                enquiryTypes={enquiryTypes}
                enquiryStatuses={enquiryStatuses}
                enquiryStartDate={enquiryStartDate}
                enquiryEndDate={enquiryEndDate}
                assigneesSelected={assigneesSelected}
            />
        </Paper>
    );
}
