import { InsertDriveFile, OpenInNew, PictureAsPdf } from '@mui/icons-material';
import { Box, Button, Chip, Grid, Typography, useTheme } from '@mui/material';
import { common, grey } from '@mui/material/colors';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Communication, DeliveryType } from '../../apis/communications';
import { DATE_TIME_VERBOSE } from '../../util/dateUtils';
import { readableNotificationType } from './utils';
type Props = {
    communication: Communication;
    invoiceIdentifier?: string;
};
export default function Details({ communication, invoiceIdentifier }: Readonly<Props>) {
    const theme = useTheme();
    const isEmail = communication.template.templateType === DeliveryType.EMAIL;

    return (
        <Box sx={{ borderColor: grey[400], borderStyle: 'solid', borderRadius: `${theme.shape.borderRadius}px` }}>
            <Box
                sx={{
                    backgroundColor: grey[50],
                    borderTopLeftRadius: theme.shape.borderRadius,
                    borderTopRightRadius: theme.shape.borderRadius,
                    p: 2,
                }}
            >
                <Grid container>
                    <DetailRow title='Type'>
                        <>
                            <Typography variant='caption'>
                                {readableNotificationType(communication.template.notificationType)}
                            </Typography>
                            {invoiceIdentifier == null && communication.invoiceIdentifier != invoiceIdentifier && (
                                <Button
                                    data-testid='view_invoice'
                                    variant='outlined'
                                    size='small'
                                    endIcon={<OpenInNew />}
                                    sx={{ ml: 2 }}
                                    component={Link}
                                    to={`/invoices/${communication.invoiceIdentifier}`}
                                    target='_blank'
                                >
                                    View Policy
                                </Button>
                            )}
                        </>
                    </DetailRow>

                    {isEmail && (
                        <>
                            <DetailRow title='Subject'>
                                <Typography variant='caption'>{communication.subject}</Typography>
                            </DetailRow>

                            <DetailRow title='From'>
                                <Typography variant='caption'>{communication.fromAddress}</Typography>
                            </DetailRow>
                        </>
                    )}

                    <DetailRow title='To'>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Typography variant='caption'>{communication.toAddress}</Typography>
                            <FailedChip emailAddress={communication.toAddress} communication={communication} />
                        </Box>
                    </DetailRow>

                    {isEmail && (
                        <DetailRow title='BCC'>
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <Typography variant='caption'>{communication.bcc ? communication.bcc : '-'}</Typography>
                                <FailedChip emailAddress={communication.bcc} communication={communication} />
                            </Box>
                        </DetailRow>
                    )}

                    <DetailRow title='Date'>
                        <Typography variant='caption'>
                            {moment.utc(communication.submittedAt).local().format(DATE_TIME_VERBOSE)}
                        </Typography>
                    </DetailRow>

                    {isEmail && (
                        <DetailRow title='Attachments'>
                            <Typography variant='caption'>
                                {communication.attachments == null || communication.attachments.length === 0 ? (
                                    <Typography variant='caption'>-</Typography>
                                ) : (
                                    communication.attachments.map((a) => (
                                        <Chip
                                            key={a.contentId}
                                            label={a.name}
                                            variant='outlined'
                                            icon={getIcon(a.contentType)}
                                            sx={{ p: 1, height: 30, mr: 1 }}
                                        />
                                    ))
                                )}
                            </Typography>
                        </DetailRow>
                    )}
                </Grid>
            </Box>
            <Box
                sx={{
                    backgroundColor: grey[200],
                    borderBottomLeftRadius: theme.shape.borderRadius,
                    borderBottomRightRadius: theme.shape.borderRadius,
                    py: 4,
                }}
            >
                {isEmail && communication.notificationDetail && (
                    <div
                        style={{ height: '100%', overflow: 'auto' }}
                        dangerouslySetInnerHTML={{ __html: communication.notificationDetail.messageBody }}
                    ></div>
                )}
                {!isEmail && communication.notificationDetail && (
                    <Box
                        sx={{
                            mx: 4,
                            p: 3,
                            backgroundColor: common.white,
                            borderRadius: `${theme.shape.borderRadius}px`,
                        }}
                    >
                        {communication.notificationDetail.messageBody}
                    </Box>
                )}
            </Box>
        </Box>
    );
}

type FailedChipProps = {
    emailAddress?: string;
    communication: Communication;
};

const FailedChip = ({ emailAddress, communication }: Readonly<FailedChipProps>) => {
    const didFailToSend = failedToSend(communication, emailAddress);

    if (!didFailToSend) {
        return null;
    }

    return <Chip color='error' label='Failed to send' variant='outlined' />;
};

const failedToSend = (communication: Communication, emailAddress?: string): boolean => {
    if (!emailAddress) {
        return false;
    }

    return communication.bouncedEmails?.includes(emailAddress) ?? false;
};

const getIcon = (contentType: string): JSX.Element | undefined => {
    switch (contentType) {
        case 'application/pdf':
            return <PictureAsPdf fontSize='small' color='error' />;
        case 'text/csv; charset=utf-8':
            return <InsertDriveFile fontSize='small' color='success' />;
        default:
            return undefined;
    }
};

type DetailRowProps = {
    title: string;
    children: React.ReactNode;
};

const DetailRow = ({ title, children }: Readonly<DetailRowProps>) => {
    return (
        <>
            <Grid item xs={3} sx={{ display: 'flex', mt: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant='body2'>{title}</Typography>
                </Box>
            </Grid>
            <Grid item xs={9} sx={{ display: 'flex', mt: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>{children}</Box>
            </Grid>
        </>
    );
};
