import axios from 'axios';
import { assignDefaultListParams, Direction, ListParams } from './common';

export type EnquiryType = {
    uuid: string;
    name: string;
};

export type EnquiryDetail = {
    clientName: string;
    emailAddress: string;
    mobileNumber: string;
    enquiryType: string;
    rawDetails: string;
};

export enum ActivityType {
    ASSIGNMENT = 'ASSIGNMENT',
    CONTACT = 'CONTACT',
    NOTE = 'NOTE',
    STATUS_UPDATE = 'STATUS_UPDATE',
}

export type Activity = {
    uuid: string;
    activityType: ActivityType;
    createdDate: string;
    sellerUserIdentifier: string;
    sellerUserName: string;
};

export enum ContactType {
    CALL_OUTBOUND = 'CALL_OUTBOUND',
    CALL_INBOUND = 'CALL_INBOUND',
    SMS = 'SMS',
}

export enum OutcomeType {
    COMPLETED_CALL = 'COMPLETED_CALL',
    LEFT_VOICEMAIL = 'LEFT_VOICEMAIL',
    CALL_FAILED = 'CALL_FAILED',
    CALL_ATTEMPTED_NO_ANSWER = 'CALL_ATTEMPTED_NO_ANSWER',
    CALL_DISCONNECTED = 'CALL_DISCONNECTED',
    BUSY_SIGNAL = 'BUSY_SIGNAL',
    OTHER = 'OTHER',
}

export type ContactOutcome = {
    outcomeType: OutcomeType;
    contactDateTime: string;
    description: string;
};

export type Contact = Activity & {
    contactType: ContactType;
    contactDateTime: string;
    notificationIdentifier: string;
    noteDetails: string;
    contactOutcomes: ContactOutcome[];
};

export type Assignment = Activity & {
    oldAssigneeIdentifier: string;
    newAssigneeIdentifier: string;
};

export type Note = Activity & {
    details: string;
};

export enum EnquiryStatus {
    NEW = 'NEW',
    IN_PROGRESS = 'IN_PROGRESS',
    ON_HOLD = 'ON_HOLD',
    SPAM = 'SPAM',
    CLOSED = 'CLOSED',
}

export type StatusUpdate = Activity & {
    oldStatus: EnquiryStatus;
    newStatus: EnquiryStatus;
};

export type ActivityVariation = Contact | Assignment | Note | StatusUpdate;

export type EnquirySummary = {
    uuid: string;
    enquiryType: string;
    clientName: string;
    createdDate: string;
    lastContactDateTime?: string;
    status: EnquiryStatus;
    assigneeIdentifier?: string;
    assigneeName?: string;
};

export type EnquiriesPage = {
    page: number;
    pageSize: number;
    totalPages: number;
    totalRecords: number;
    records: EnquirySummary[];
};

export enum EnquirySortProperty {
    TYPE = 'TYPE',
    NAME = 'NAME',
    ENQUIRY_DATE = 'ENQUIRY_DATE',
    LAST_CONTACT_DATE_TIME = 'LAST_CONTACT_DATE_TIME',
    STATUS = 'STATUS',
    ASSIGNEE = 'ASSIGNEE',
}

type ListEnquiriesParams = ListParams & {
    enquirySortProperty: EnquirySortProperty;
    direction: Direction;
    enquiryCreateStartString?: string;
    enquiryCreateEndString?: string;
    enquiryTypes?: string[];
    enquiryStatuses?: EnquiryStatus[];
    enquiryDateFrom?: string;
    enquiryDateTo?: string;
    assigneeIdentifiers?: string[];
    includeNullAssignee: boolean;
};

export type EnquiryFormOrigin = {
    url: string;
};

export type EnquiryConfigurationDetail = {
    uuid: string;
    formName: string;
    active: boolean;
    description: string;
    formConfiguration: string;
    version: string;
    completionMessage: string;
    enquiryType: EnquiryType;
    enquiryFormOrigins: EnquiryFormOrigin[];
};

export type EnquiryConfiguration = {
    uuid: string;
    standaloneFormUrl: string;
    enquiryConfigurationDetail: EnquiryConfigurationDetail;
};

export const UNASSIGNED_FLAG = 'Unassigned';

export const fetchActiveEnquiryConfigurations = async (): Promise<EnquiryConfiguration[]> => {
    return await axios
        .post(process.env.REACT_APP_BROKER_PORTAL_HOST + '/enquiry/configuration/_query_all_active')
        .then(({ data }) => data);
};

export const fetchEnquiryTypes = async (): Promise<EnquiryType[]> => {
    return await axios
        .get(process.env.REACT_APP_BROKER_PORTAL_HOST + '/enquiry/configuration/types')
        .then(({ data }) => {
            if (data.length == 0) {
                throw new Error('No enquiry types found');
            }
            return data;
        });
};

export const searchEnquiries = async (partial?: Partial<ListEnquiriesParams>): Promise<EnquiriesPage> => {
    const searchRequest = assignDefaultListParams(partial);
    return await axios
        .post(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/enquiry/_query/worklist`, searchRequest)
        .then(({ data }) => data);
};
