export type Endorsement = {
    policyEndorsements: EndorsementPolicy[];
    number: string; // reference
    reason?: string; // description
    uuid: string;
};

export type EndorsementPolicy = {
    uuid: string;
    policyNumber: string;
    premiumsAdjustment: number;
    effectiveDate: string;
    policyUuid: string;
    status: EndorsementPolicyStatus;
    policyVersionIdentifier: string;
    instalmentFeeAdjustment: number;
};

export enum EndorsementPolicyStatus {
    PENDING = 'PENDING', // pending client acceptance
    ACCEPTED = 'ACCEPTED', // client accepted but pending action, e.g. effective date is in future
    ACTIONED = 'ACTIONED', // endorsement actioned on the policy, i.e. effective
    REJECTED = 'REJECTED', // client rejected the endorsement, or system rejected the endorsement, e.g. lending rule is not satisfied
    REPLACED = 'REPLACED', // replaced by a new endorsement on the same policy when the current one wasn't actioned (effective)
}
