import { capitalize } from 'lodash';

export const firstNameInString = (fullname: string) => {
    return capitalize(fullname.split(' ').filter((word) => word)[0]);
};

export const firstCharsInString = (name: string) => {
    const nameInArray = name.split(' ').filter((word) => word);
    const firstCharsInArray = nameInArray.map((word) => capitalize(word[0]));
    return firstCharsInArray.length == 1
        ? firstCharsInArray[0]
        : firstCharsInArray[0] + firstCharsInArray[firstCharsInArray.length - 1];
};
