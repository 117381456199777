import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import { PaymentMethodType } from './clientLead';
import { Client } from './clients';
import { Direction, ListParams, SearchSearchResult, assignDefaultListParams } from './common';
import { Endorsement } from './endorsement';
import { Lender } from './lenders';
import { Action, Arrangement } from './variations';
import { PolicyVersion } from './quotes';

export type BrokerDashboardData = {
    invoicesPaid: number[];
    invoicesIssued: number[];
    invoicesDates: Date[];

    invoicesPaidValue: number;
    invoicesPaidVolume: number;

    invoicesIssuedValue: number;
    invoicesIssuedVolume: number;

    invoicesAtRiskValue: number;
    invoicesAtRiskVolume: number;

    invoicesInProgressValue: number;
    invoicesInProgressVolume: number;
};

export type InvoicePage = {
    page: number;
    pageSize: number;
    totalPages: number;
    totalRecords: number;
    records: InvoiceSearchResult[];
};

export type InvoiceSearchResult = {
    status: string;
    issuedDate: Date;
    premiums: number;
    identifier: string;
    quoteNumber: string;
    customerName: string;
    invoiceNumber: string;
    paymentFrequency: PaymentFrequency | undefined;
};

export enum InvoiceStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    PAID = 'PAID',
    ATTENTION = 'ATTENTION',
    CANCELLED = 'CANCELLED',
}

export enum RuleEvaluationOutcome {
    APPROVED = 'APPROVED',
    DENIED = 'DENIED',
    REFER = 'REFER',
}

export enum RuleEvaluationReason {
    CLASS_CODE_NOT_ACTIVE = 'CLASS_CODE_NOT_ACTIVE',
    CLASS_CODE_NOT_CANCELLABLE = 'CLASS_CODE_NOT_CANCELLABLE',
    CLASS_CODE_NOT_SUPPORTED = 'CLASS_CODE_NOT_SUPPORTED',
    PROVIDER_NOT_ACTIVE = 'PROVIDER_NOT_ACTIVE',
    PROVIDER_NOT_SUPPORTED = 'PROVIDER_NOT_SUPPORTED',
    MIN_TERM_NOT_MET = 'MIN_TERM_NOT_MET',
    MAX_TERM_EXCEEDED = 'MAX_TERM_EXCEEDED',
    MIN_PREMIUM_VALUE_NOT_REACHED = 'MIN_PREMIUM_VALUE_NOT_REACHED',
    MAX_PREMIUM_VALUE_EXCEEDED = 'MAX_PREMIUM_VALUE_EXCEEDED',
}

export type InvoiceDetailsResponse = {
    invoice: Invoice;
    client: Client;
    loan: InvoiceLoanAnnotation;
    settlement: InvoiceSettlementAnnotation | undefined;
    lender: Lender | undefined;
    allowedActions?: InvoiceAction[];
};

export enum LoanStatus {
    APPLICATION = 'APPLICATION',
    OPEN = 'OPEN',
    APPROVAL_PENDING = 'APPROVAL_PENDING',
    CLOSED = 'CLOSED',
}

export enum LoanSubStatus {
    PENDING_CANCELLATION = 'PENDING_CANCELLATION',
    CANCELLED_PENDING_SETTLEMENT = 'CANCELLED_PENDING_SETTLEMENT',
    CANCELLED = 'CANCELLED',
    ARRANGEMENT = 'ARRANGEMENT',
    PARTIALLY_PAID = 'PARTIALLY_PAID',
    OVERDUE = 'OVERDUE',
}

export type InvoiceLoanAnnotation = {
    paymentFrequency: PaymentFrequency;
    status: LoanStatus;
    subStatus: LoanSubStatus;
};

export enum SettlementStatus {
    SUCCEEDED = 'SUCCEEDED',
    PROCESSING = 'PROCESSING',
    SETTLEMENT_FAILED = 'SETTLEMENT_FAILED',
    INITIATED = 'INITIATED',
    READY_TO_SETTLE = 'READY_TO_SETTLE',
    ERROR = 'ERROR',
}

export type InvoiceSettlementAnnotation = {
    settlementStatus: SettlementStatus;
    settlementDate: string;
};

export type Invoice = {
    term: Term;
    portfolio: Portfolio;
    uuid: string;
    number: string;
    status: InvoiceStatus;
    client: Client;
    dueDate: string;
    paidDate: string;
    issuedDate: string;
    brokerIdentifier: string | undefined;
    clientIdentifier: string | undefined;
    repaymentTerms: Term[];
    ruleEvaluationResults: RuleEvaluationResult[] | undefined;
    checkoutAllowed: boolean;
    lastLcaRequestedDate: string | undefined;
    customerInvoiceReference: string | undefined;
    endorsements?: Endorsement[];
    totalPremiums: number;
    termsAgreedDate?: string;
    canRenew: boolean;
    canSendRenewalToCustomer: boolean;
    renewals: Invoice[];
    renewalNotificationDate?: string;
    migrationStatus?: MigrationStatus;
    invoiceExternalProviderDetail?: InvoiceExternalProviderDetail;
    sellerProductIdentifier: string;
    productLogoUrl?: string;
};

export enum CustomCheckoutType {
    IQUMULATE = 'IQUMULATE',
    NONE = 'NONE',
}

export enum MigrationStatus {
    NONE = 'NONE',
    PARTIAL = 'PARTIAL',
    FULL = 'FULL',
}

export enum InvoiceAction {
    VIEW = 'VIEW',
    ENDORSE = 'ENDORSE',
    CANCEL = 'CANCEL',
    PAYMENT_VARIATION = 'PAYMENT_VARIATION',
    ADD_PAYMENT = 'ADD_PAYMENT',
}

export type InvoiceExternalProviderDetail = {
    checkoutType: string;
    externalProviderReference: string;
};

export type RuleEvaluationResult = {
    uuid?: string;
    outcome: RuleEvaluationOutcome;
    reason: RuleEvaluationReason | undefined;
    hints: string | undefined;
    conditionApproved: boolean;
    ruleIdentifier?: string;
};

export type Portfolio = {
    uuid: string;
    premiums: number;
    policies: Policy[];
};

export type Policy = {
    uuid: string;
    number: string;
    provider: string;
    classCode: string;
    classCodeDescription?: string;
    currentPolicyVersion: PolicyVersion;
    policyVersions: PolicyVersion[];
};

export type TermPaymentMethod = {
    paymentMethodType: PaymentMethodType;
    paymentMethodCharges: Charge[];
};

export type Term = {
    uuid: string;
    feeAmount: number;
    numberOfPayments: number;
    totalAmount: number;
    instalmentAmount: number;
    interestAmount: number;
    feeCharges: Charge[];
    flatInterestRate: number;
    annualInterestRate: number;
    totalInterestAmount: number;
    initialPaymentAmount: number;
    paymentFrequency: PaymentFrequency;
    effectiveDate: string;
    nextPaymentDate: string;
    firstPaymentDate: string;
    finalPaymentDate: string;
    termPaymentMethod: TermPaymentMethod;
};

export enum PaymentFrequency {
    IN_FULL = 'IN_FULL',
    WEEKLY = 'WEEKLY',
    FORTNIGHTLY = 'FORTNIGHTLY',
    MONTHLY = 'MONTHLY',
}

export enum ChargeType {
    TRANSACTION_FEE = 'TRANSACTION_FEE',
    SETUP_FEE = 'SETUP_FEE',
    DEFAULT_FEE = 'DEFAULT_FEE',
}

export enum CalculatedOn {
    PERCENT = 'PERCENT',
    FIXED = 'FIXED',
}

export type Charge = {
    type: ChargeType;
    amount: number;
    initialAmount: number;
    chargeIdentifier: string;
    calculatedOn: CalculatedOn;
    chargeValue: number;
    label?: string;
};

type ListInvoicesParams = ListParams & {
    searchValue?: string;
    statuses: InvoiceStatus[];
    clientUuid?: string;
};
export const listInvoices = async (partial?: Partial<ListInvoicesParams>): Promise<InvoicePage> => {
    const { pageSize, page, searchValue, statuses, clientUuid } = assignDefaultListParams(partial);

    const url = new URL(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice`);
    url.searchParams.append('pageSize', pageSize.toString());
    url.searchParams.append('page', page.toString());
    if (searchValue) {
        url.searchParams.append('search', searchValue);
    }
    if (!isEmpty(statuses)) {
        statuses.forEach((status) => url.searchParams.append('status', status));
    }
    if (clientUuid) {
        url.searchParams.append('clientIdentifier', clientUuid);
    }

    return await axios.get(url.href).then(({ data }) => data);
};

export const fetchDashboardData = async (): Promise<BrokerDashboardData> => {
    const url = new URL(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/dashboard/summary`);
    return await axios.get(url.href).then(({ data }) => data);
};

export const fetchInvoice = async (invoiceUuid: string): Promise<InvoiceDetailsResponse> => {
    return await axios
        .get(process.env.REACT_APP_BROKER_PORTAL_HOST + '/invoice/' + invoiceUuid)
        .then(({ data }) => data);
};

type CreateInvoiceReq = {
    dueDate: string;
    clientIdentifier: string;
    number: string;
    portfolio: {
        policies: Policy[];
    };
};
export enum SaveInvoiceError {
    NON_CONTIGUOUS_POLICY_DATES = '1401',
    END_DATE_BEFORE_TODAY = '1402',
    START_DATE_AFTER_END_DATE = '1403',
}

export const saveInvoice = async (invoice: CreateInvoiceReq): Promise<Invoice> => {
    return await axios.post(process.env.REACT_APP_BROKER_PORTAL_HOST + '/invoice', invoice).then(({ data }) => data);
};

export const requestApproval = async (invoiceUuid: string): Promise<Invoice> => {
    return await axios
        .post(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/${invoiceUuid}/request-approval`)
        .then(({ data }) => data);
};

export const sendInvoiceToCustomer = async (invoiceUuid: string): Promise<string> => {
    return await axios
        .post(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/${invoiceUuid}/send-to-customer`)
        .then(({ data }) => data);
};

export type Loan = {
    paymentFrequency: PaymentFrequency;
    status: LoanStatus;
    subStatus: LoanSubStatus;
    paymentTransactions: Transaction[];
    consolidatedExpectedPayments: ConsolidatedExpectedPayment[];
    consolidatedPayments: ConsolidatedPayment[];
    isPaymentScheduledToday?: boolean;
    actions: Action[];
    cancellationRequest?: LoanCancellationRequest;
    outstandingSettlementAmount?: number;
    activeExpectedPayments: ExpectedPayment[];
    paidUntilDate?: string;
    daysPastDue: number;
    firstPaymentDate?: string;
    balance: number;
    totalPaidAmount: number;
    loanStartDate: string;
    loanEndDate: string;
    isInterestsBearing: boolean;
};

export type LoanCancellationRequest = {
    reason: CancellationReason;
    note: string;
    effectiveDate?: string;
    cancellationClosingBalance?: number;
    cancellationSkippedPremiums?: number;
    status: LoanCancellationRequestStatus;
};

export enum LoanCancellationRequestStatus {
    SCHEDULED = 'SCHEDULED',
    UNDERPAID = 'UNDERPAID',
    OVERPAID = 'OVERPAID',
    COMPLETED = 'COMPLETED',
}

export enum CancellationReason {
    CLAIM = 'CLAIM',
    ARREARS = 'ARREARS',
    CLIENT_REQUESTED = 'CLIENT_REQUESTED',
    INSURER_REQUESTED = 'INSURER_REQUESTED',
    OTHER = 'OTHER',
}

export type ExpectedPayment = {
    uuid: string;
    dueDate: string;
    originalDueDate: string;
    amount: number;
    feeAmount?: number;
    paidAmount?: number;
    status: ExpectedPaymentStatus;
    causedBy: ExpectedPaymentCausedByType;
    retryDate?: string;
};

export enum ExpectedPaymentCausedByType {
    UNEXPECTED_INTEREST = 'UNEXPECTED_INTEREST',
    DEFAULT_FEE = 'DEFAULT_FEE',
    ENDORSEMENT_ADJUSTMENT = 'ENDORSEMENT_ADJUSTMENT',
}

export type ConsolidatedExpectedPayment = ExpectedPayment & {
    expectedPayments: ExpectedPayment[];
};

export type ConsolidatedPayment = {
    identifier: string;
    dueDate: string;
    amount: number;
    feeAmount?: number;
    paidAmount?: number;
    status: ExpectedPaymentStatus;
    consolidatedExpectedPayments: ConsolidatedExpectedPayment[];
    arrangements: Arrangement[];
};

export enum ExpectedPaymentStatus {
    PENDING = 'PENDING',
    PAID = 'PAID',
    OVERDUE = 'OVERDUE',
    WAIVED = 'WAIVED',
    DEFERRED = 'DEFERRED',
    ARRANGEMENT = 'ARRANGEMENT',
    REPLACED = 'REPLACED',
    VOIDED = 'VOIDED',
}

export type Transaction = {
    transactionType: TransactionType;
    amount: number;
    feeAmount: number;
    transactionDate: string;
    confirmationDate?: string;
    expectedPaymentTransactionMapping: ExpectedPaymentTransactionMapping[];
    arrangementTransactionMapping: ArrangementTransactionMapping[];
    dishonourReason?: DishonourReason;
    dishonourDate?: string;
};

export enum TransactionType {
    PAYMENT = 'PAYMENT',
    EXTERNAL_TRANSACTION = 'EXTERNAL_TRANSACTION',
}

export type DishonourReason = {
    code: string;
    source: DishonourReasonSource;
};

export enum DishonourReasonSource {
    BNZ = 'BNZ',
    CYBERSOURCE = 'CYBERSOURCE',
    INTERNAL = 'INTERNAL',
}

export type ExpectedPaymentTransactionMapping = {
    amountAllocated: number;
    expectedPayment: ExpectedPayment;
};

export type ArrangementTransactionMapping = {
    amountAllocated: number;
    arrangement: Arrangement;
};

export const fetchLoan = async (invoiceUuid: string): Promise<Loan> => {
    return await axios
        .get(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/${invoiceUuid}/loan`)
        .then(({ data }) => data);
};

export const completeLoanCancellation = async (invoiceUuid: string): Promise<Loan> => {
    return await axios
        .post(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/${invoiceUuid}/complete-cancellation`)
        .then(({ data }) => data);
};

export const getCheckoutOrigin = async (): Promise<string | null> => {
    return await axios
        .get(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoice/config/checkout-origin`)
        .then(({ data }) => data);
};

export enum InvoiceNoteSortProperty {
    CREATED_DATE = 'CREATED_DATE',
}

export type InvoiceNoteDto = {
    uuid: string;
    user: string;
    contents: string;
    createdDate: Date;
};

export type InvoiceNotePage = SearchSearchResult<InvoiceNoteDto>;

type ListInvoiceNoteParams = ListParams & {
    invoiceNoteSortProperty?: InvoiceNoteSortProperty;
    direction?: Direction;
};

export const searchInvoiceNotes = async (
    invoiceIdentifier: string,
    partial?: Partial<ListInvoiceNoteParams>
): Promise<InvoiceNotePage> => {
    const searchRequest = assignDefaultListParams(partial);
    return await axios
        .post(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoices/${invoiceIdentifier}/notes/_query`, searchRequest)
        .then(({ data }) => data);
};

export type CreateInvoiceNoteFields = {
    contents: string;
};

export const saveInvoiceNote = async (
    invoiceIdentifier: string,
    data: CreateInvoiceNoteFields
): Promise<InvoiceNoteDto> => {
    return await axios
        .post(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/invoices/${invoiceIdentifier}/notes`, data)
        .then(({ data }) => data);
};
