import { ExpandMore, Lock, NavigateNext, Send } from '@mui/icons-material';
import { Box, Button, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { InvoiceDetailsResponse, InvoiceStatus, requestApproval } from '../../../apis/invoice';
import { sendRenewalToCustomer } from '../../../apis/renewal';
import { SnackState } from '../../../components/SnackAlert';
import { UndoButton } from '../../../components/UndoButton';
import { isLendingConditionPendingApproval } from '../common';
import VoidRenewalInvoice from '../Renewal/VoidRenewalInvoice';
import SendInvoiceConfirmationDialog from './SendInvoiceConfirmationDialog';

type Props = {
    invoiceDetails: InvoiceDetailsResponse;
    setSnack: (state: SnackState) => void;
    approvalRequestSentCount: number;
    setApprovalRequestSentCount: (count: number) => void;
};

export default function RenewalActions({
    invoiceDetails,
    setSnack,
    approvalRequestSentCount,
    setApprovalRequestSentCount,
}: Readonly<Props>) {
    const [openVoidDialog, setOpenVoidDialog] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const actionMenuOpen = Boolean(anchorEl);

    const [sendingInvoice, setSendingInvoice] = useState(false);
    const [confirmationDialogOpened, setConfirmationDialogOpened] = useState(false);
    const [requestingApproval, setRequestingApproval] = useState(false);

    const [invoice, setInvoice] = useState(invoiceDetails.invoice);

    useEffect(() => {
        setInvoice(invoiceDetails.invoice);
    }, [invoiceDetails.invoice]);

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const closeActionMenu = () => {
        setAnchorEl(null);
        setOpenVoidDialog(false);
    };

    const sendInvoice = useCallback(async () => {
        return sendRenewalToCustomer(invoice.uuid)
            .then((updatedInvoice) => {
                setInvoice(updatedInvoice);
                setSnack({ severity: 'success', msg: 'Invoice sent to customer' });
            })
            .catch(() => {
                setSnack({
                    severity: 'error',
                    msg: 'Invoice failed to send to the customer. Please try again or contact Simfuni for assistance.',
                });
            });
    }, [invoice.uuid]);

    const handleRequestApproval = useCallback(async () => {
        setRequestingApproval(true);
        return requestApproval(invoice.uuid)
            .then(() => {
                setApprovalRequestSentCount(approvalRequestSentCount + 1);
            })
            .finally(() => setRequestingApproval(false));
    }, [approvalRequestSentCount, invoice.uuid]);

    const handleSendInvoice = () => {
        setConfirmationDialogOpened(false);
        setSendingInvoice(true);
        closeActionMenu();
    };

    const lendingConditionPendingApproval = isLendingConditionPendingApproval(invoice);
    const SendActionMenuButton = useCallback(() => {
        if (!invoice.canSendRenewalToCustomer) {
            return (
                <Tooltip title={blockedSendingInvoiceTooltipMsg} arrow placement='left'>
                    <span>
                        {' '}
                        {/* Add <span> to make tooltip work with disabled button. See https://mui.com/material-ui/react-tooltip/#disabled-elements for details. */}
                        <MenuItem disabled={true} data-testid='send-invoice-disabled-menu-item'>
                            <ListItemIcon>
                                <Lock fontSize='small' />
                            </ListItemIcon>
                            <ListItemText>Send Invoice</ListItemText>
                        </MenuItem>
                    </span>
                </Tooltip>
            );
        }

        if (!lendingConditionPendingApproval) {
            return (
                <MenuItem onClick={handleSendInvoice} data-testid='send-invoice-menu-item'>
                    <ListItemIcon>
                        <Send fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Send Invoice</ListItemText>
                </MenuItem>
            );
        }

        return (
            <MenuItem
                onClick={() => {
                    setConfirmationDialogOpened(true);
                }}
                data-testid='open-confirmation-dialog-menu-item'
            >
                <ListItemIcon>
                    <Send fontSize='small' />
                </ListItemIcon>
                <ListItemText>Send Invoice</ListItemText>
            </MenuItem>
        );
    }, [lendingConditionPendingApproval, invoice]);

    return (
        <>
            {sendingInvoice && (
                <UndoButton
                    handleCancel={() => setSendingInvoice(false)}
                    delayedFunction={sendInvoice}
                    handleFinish={() => setSendingInvoice(false)}
                    startImmediately={true}
                    variant='contained'
                    sx={buttonStyle}
                >
                    Sending
                </UndoButton>
            )}
            <Box sx={sendingInvoice ? hiddenStyles : {}}>
                <Button
                    data-testid='invoice-actions'
                    sx={{ ...buttonStyle, justifyContent: 'space-between' }}
                    id='invoice-action'
                    aria-controls={actionMenuOpen ? 'action-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={actionMenuOpen ? 'true' : undefined}
                    onClick={handleOpenMenu}
                    variant='contained'
                    endIcon={actionMenuOpen ? <ExpandMore /> : <NavigateNext />}
                    disabled={invoiceDetails.invoice.status === InvoiceStatus.CANCELLED}
                >
                    Actions
                </Button>
            </Box>
            <Menu
                id='action-menu'
                anchorEl={anchorEl}
                open={actionMenuOpen}
                onClose={closeActionMenu}
                MenuListProps={{
                    'aria-labelledby': 'invoice-action',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <SendActionMenuButton />
                <MenuItem onClick={() => setOpenVoidDialog(true)} sx={buttonStyle}>
                    <ListItemText>Void</ListItemText>
                </MenuItem>
            </Menu>
            <SendInvoiceConfirmationDialog
                invoice={invoice}
                confirmationDialogOpened={confirmationDialogOpened}
                setConfirmationDialogOpened={setConfirmationDialogOpened}
                requestingApproval={requestingApproval}
                handleRequestApproval={handleRequestApproval}
                handleSendInvoice={handleSendInvoice}
            />
            <VoidRenewalInvoice open={openVoidDialog} setClosed={closeActionMenu} invoiceDetails={invoiceDetails} />
        </>
    );
}

const hiddenStyles = {
    visibility: 'hidden',
    height: 0,
    width: 0,
    padding: 0,
};
const blockedSendingInvoiceTooltipMsg = 'The invoice was already sent to customer.';

const buttonStyle = { minWidth: 160 };
