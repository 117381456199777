import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    InputAdornment,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import {
    Control,
    Controller,
    FieldArrayWithId,
    FieldErrors,
    FieldPath,
    useFieldArray,
    UseFormRegister,
    UseFormSetValue,
    UseFormTrigger,
} from 'react-hook-form';
import CovertNumberTextField from '../../CovertNumberTextField';
import { QuoteFields } from '../common';

type Props = {
    control: Control<QuoteFields>;
    register: UseFormRegister<QuoteFields>;
    errors: FieldErrors<QuoteFields>;
    setValue: UseFormSetValue<QuoteFields>;
    trigger: UseFormTrigger<QuoteFields>;
    itemIndex: number;
};

export default function CoverFields({ control, itemIndex, register, errors, setValue, trigger }: Readonly<Props>) {
    const { fields } = useFieldArray({
        control,
        name: `items.${itemIndex}.covers`,
    });
    const errorMsg = errors.items?.[itemIndex]?.covers?.message;

    return (
        <Box>
            <Typography variant='h2' component='p' color={errorMsg ? 'error' : 'inherit'}>
                Covers
            </Typography>
            {fields.map((field, index) => (
                <CoverField
                    key={field.id}
                    field={field}
                    coverIndex={index}
                    itemIndex={itemIndex}
                    control={control}
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    trigger={trigger}
                />
            ))}
            {errorMsg && <FormHelperText error>{errorMsg}</FormHelperText>}
        </Box>
    );
}

type CoverFieldProps = {
    control: Control<QuoteFields>;
    register: UseFormRegister<QuoteFields>;
    errors: FieldErrors<QuoteFields>;
    setValue: UseFormSetValue<QuoteFields>;
    trigger: UseFormTrigger<QuoteFields>;
    field: FieldArrayWithId<QuoteFields, `items.${number}.covers`, 'id'>;
    itemIndex: number;
    coverIndex: number;
};

function CoverField({
    field,
    itemIndex,
    coverIndex,
    control,
    register,
    errors,
    setValue,
    trigger,
}: Readonly<CoverFieldProps>) {
    const [enabled, setEnabled] = useState(field.coverageAmount > 0);
    const error = errors.items?.[itemIndex]?.covers?.[coverIndex];
    const fieldName: FieldPath<QuoteFields> = `items.${itemIndex}.covers.${coverIndex}.coverageAmount`;

    const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEnabled(event.target.checked);
        setValue(fieldName, 0);
    };

    if (field.fixedCoverage) {
        return null;
    }

    return (
        <Box>
            <FormGroup sx={{ width: 360 }}>
                <FormControlLabel
                    control={<Checkbox onChange={handleCheck} size='small' checked={enabled} />}
                    label={`${field.productCoverName} (${field.productCoverCode})`}
                />
            </FormGroup>
            <FormControl fullWidth sx={{ display: enabled ? 'inherit' : 'none', mb: 1, ml: '26px' }}>
                <FormLabel htmlFor={fieldName}>Sum insured</FormLabel>
                <Controller
                    control={control}
                    name={fieldName}
                    defaultValue={'' as unknown as undefined}
                    disabled={!enabled}
                    render={({ field }) => (
                        <CovertNumberTextField
                            {...field}
                            {...register(fieldName)}
                            id={fieldName}
                            InputProps={{
                                startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                            }}
                            onChange={(event) => {
                                if (errors.items?.[itemIndex]?.covers) {
                                    trigger(`items.${itemIndex}.covers`);
                                }
                                field.onChange(event);
                            }}
                            sx={{ width: 360 }}
                            fullWidth
                            autoComplete='no'
                            size='small'
                            error={!!error?.coverageAmount}
                            helperText={error?.coverageAmount?.message}
                        />
                    )}
                />
            </FormControl>
        </Box>
    );
}
