import { Alert, Box, Breadcrumbs, Button, CircularProgress, Drawer, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AddCircleOutline, Edit } from '@mui/icons-material';
import PersonalDetailsSection from './PersonalDetailsSection';
import { Link, useParams } from 'react-router-dom';
import CustomerInvoices from './CustomerInvoices';
import ClientNotes from './ClientNotes';
import ClientSummary from './ClientSummary';
import InvoiceForm from '../CreateInvoice/InvoiceForm';
import { Client, getClient } from '../../apis/clients';
import CreateClient from '../CreateClient';
import { useAppSelector } from '../../store/reducer/Hooks';
import Communications from '../../components/Communications';

const ClientDetails = () => {
    const { permissions } = useAppSelector((state) => state.UserSessionReducer);
    const { clientUpdateAllowed, invoiceUpdateAllowed, invoiceAllowed } = permissions;

    const { uuid } = useParams<{ uuid?: string }>();

    const [client, setClient] = useState<Client | undefined>({} as Client);
    const [ready, setReady] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [editClient, setEditClient] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const closeEditClient = () => setEditClient(false);
    const openEditClient = () => setEditClient(true);

    const fetchClient = () => {
        if (!uuid) {
            return;
        }
        getClient(uuid)
            .then((response) => {
                setClient(response);
                setReady(true);
            })
            .catch(console.log);
    };

    const refreshClient = (client: Client) => {
        setClient(client);
        closeEditClient();
    };

    useEffect(() => {
        fetchClient();
    }, [uuid]);

    return (
        <>
            <Grid container>
                <Grid item sm={6}>
                    <Breadcrumbs aria-label='breadcrumb'>
                        <Link to={'/clients'}>Clients</Link>
                        {client && <Box>{client.displayName}</Box>}
                    </Breadcrumbs>
                </Grid>
                <Grid item sm={6} style={{ color: '#FFF', textAlign: 'right' }}>
                    {clientUpdateAllowed && (
                        <Button variant='contained' startIcon={<Edit />} onClick={openEditClient}>
                            Edit client
                        </Button>
                    )}
                    {invoiceUpdateAllowed && (
                        <Button
                            variant='contained'
                            startIcon={<AddCircleOutline />}
                            onClick={handleOpen}
                            sx={{ ml: 2 }}
                        >
                            Create Invoice
                        </Button>
                    )}
                </Grid>
            </Grid>
            {ready && client ? (
                <>
                    <Grid container spacing={2}>
                        <Grid item md={8} sm={12}>
                            <PersonalDetailsSection client={client} />
                            <Paper variant='flat' sx={{ p: 2, mt: 2 }}>
                                <Typography variant='h2'>Policies</Typography>
                                {invoiceAllowed && <CustomerInvoices clientUuid={client.uuid} />}
                                {!invoiceAllowed && (
                                    <Alert severity='info'>
                                        Your account doesn't have permission to view invoices. If you need help, contact
                                        your account admin.
                                    </Alert>
                                )}
                            </Paper>
                            <Box mt={2}>
                                <Communications insuredIdentifier={client.uuid} />
                            </Box>
                        </Grid>
                        <Grid item md={4} sm={12}>
                            <ClientSummary client={client} />
                            <ClientNotes client={client} />
                        </Grid>
                    </Grid>
                </>
            ) : (
                <Typography style={{ textAlign: 'center', paddingTop: '20px' }}>
                    <CircularProgress size={40} style={{ color: '#00063f' }} />
                </Typography>
            )}

            <Drawer
                anchor='right'
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: { width: '75%', maxWidth: '1200px' },
                }}
            >
                <Box sx={{ p: 2 }}>
                    <Typography variant='h6' sx={{ mb: '15px' }}>
                        New invoice
                    </Typography>
                    <InvoiceForm client={client} setClient={setClient} />
                </Box>
            </Drawer>

            <Drawer
                anchor='right'
                open={editClient}
                onClose={closeEditClient}
                PaperProps={{
                    sx: { width: '50%', maxWidth: '1200px' },
                }}
            >
                <CreateClient client={client} callback={refreshClient} />
            </Drawer>
        </>
    );
};

export default ClientDetails;
