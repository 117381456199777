import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormControl, FormLabel, TextField, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Invoice, Policy } from '../../../apis/invoice';
import { invalidDate } from '../../../util/dateUtils';
import PortfolioDetails from './PortfolioDetails';
import { BaseInvoiceFormFields } from '../../CreateInvoice/invoiceValidation';

type FromContext<T> = {
    from: { value: T }[];
};

const getSchema = (premiums: number) =>
    yup.object({
        invoiceNumber: yup.string().required('Invoice number required'),
        policies: yup.array().of(
            yup.object({
                number: yup
                    .string()
                    .required('Number required')
                    .test('policyNameUnique', 'Must be unique', (value, context) => {
                        const allPolicies = (context as unknown as FromContext<BaseInvoiceFormFields>).from[1].value
                            .policies;
                        return allPolicies.filter((p: Policy) => p.number === value).length === 1;
                    }),
                classCode: yup.string(),
                provider: yup.string(),
                currentPolicyVersion: yup.object({
                    premiums: yup
                        .number()
                        .required('Premiums required')
                        .positive('Positive value required')
                        .typeError('Number required')
                        .test(
                            'premiumsSum',
                            `Premiums cannot be greater than ${currencyFormat.format(premiums)}`,
                            (_value, context) => {
                                const allPolicies = (context as unknown as FromContext<BaseInvoiceFormFields>).from[2]
                                    .value.policies;
                                const sum = allPolicies.reduce(
                                    (a, policy) => a + Number(policy.currentPolicyVersion.annualPremiums),
                                    0
                                );
                                return sum <= premiums;
                            }
                        ),
                }),
                startDate: yup.date().typeError('Start date required').required('Start date required'),
                endDate: yup
                    .date()
                    .typeError('End date required')
                    .required('End date required')
                    .when('startDate', (startDate, schema) => {
                        const targetDate = startDate && !invalidDate(startDate) ? startDate : moment();
                        return schema.min(targetDate, 'End date must be after start date');
                    }),
            })
        ),
    });

type Props = {
    invoice: Invoice;
    saveCounter?: number;
    save: (fields: BaseInvoiceFormFields) => void;
};

export default function InvoiceMatcher({ invoice, saveCounter, save }: Readonly<Props>) {
    const {
        control,
        formState: { errors },
        register,
        setValue,
        handleSubmit,
    } = useForm<BaseInvoiceFormFields>({
        resolver: yupResolver(getSchema(invoice.totalPremiums)),
        defaultValues: {
            invoiceNumber: invoice.number,
            policies: [
                {
                    number: invoice.number,
                    provider: '',
                    classCode: '',
                    currentPolicyVersion: {
                        annualPremiums: invoice.totalPremiums,
                        anniversaryDate: '',
                        effectiveDate: '',
                        endDate: '',
                    },
                },
            ],
        },
    });

    useEffect(() => {
        if (saveCounter) {
            handleSubmit(onSubmit)();
        }
    }, [saveCounter]);

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'policies',
    });

    const onSubmit = (data: BaseInvoiceFormFields) => {
        save(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
                <Typography variant='subtitle2' mb={2}>
                    2. Invoice details
                </Typography>
                <Box mb={1}>
                    <FormControl required>
                        <FormLabel htmlFor='invoiceNumber'>Invoice number</FormLabel>
                        <Controller
                            name='invoiceNumber'
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    id='invoiceNumber'
                                    size='small'
                                    variant='outlined'
                                    error={!!errors.invoiceNumber}
                                    helperText={errors.invoiceNumber?.message}
                                />
                            )}
                        />
                    </FormControl>
                </Box>
                <PortfolioDetails
                    fields={fields}
                    append={append}
                    remove={remove}
                    control={control}
                    errors={errors}
                    register={register}
                    setValue={setValue}
                    invoice={invoice}
                />
            </Box>
        </form>
    );
}
const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
