import { Box } from '@mui/material';
import {
    CustomCheckoutType,
    InvoiceDetailsResponse,
    InvoiceStatus,
    Loan,
    LoanCancellationRequestStatus,
    LoanSubStatus,
    MigrationStatus,
} from '../../../apis/invoice';
import { SnackState } from '../../../components/SnackAlert';
import { FetchState, isSuccess, SuccessState } from '../../../hooks/useFetch';
import { useAppSelector } from '../../../store/reducer/Hooks';
import CancelledLoanAlert from './CancelledLoanAlert';
import CompleteCancellationRequestAlert from './CompleteCancellationRequestAlert';
import IQumulateBanner from './IQumulateBanner';
import MigratedInvoiceAlert from './MigratedInvoiceAlert';
import PartiallyPaidAlert from './PartiallyPaidAlert';
import VoidedInvoiceAlert from './VoidedInvoiceAlert';

type Props = {
    invoiceDetails: InvoiceDetailsResponse;
    handleSetSnack: (state: SnackState) => void;
};

export default function InvoiceBanners({ handleSetSnack, invoiceDetails }: Readonly<Props>) {
    const { state: loanState } = useAppSelector((root) => root.LoanReducer);
    const loanWithIQumulate =
        invoiceDetails.invoice.invoiceExternalProviderDetail?.checkoutType === CustomCheckoutType.IQUMULATE;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            {invoiceDetails.invoice.migrationStatus === MigrationStatus.PARTIAL && <MigratedInvoiceAlert />}
            {invoiceDetails.invoice.status === InvoiceStatus.CANCELLED && <VoidedInvoiceAlert />}
            {invoiceDetails.loan?.subStatus === LoanSubStatus.PARTIALLY_PAID && (
                <PartiallyPaidAlert invoice={invoiceDetails.invoice} />
            )}
            {loanCancellationRequiresCompletion(loanState) && (
                <CompleteCancellationRequestAlert invoice={invoiceDetails.invoice} setSnack={handleSetSnack} />
            )}
            {loanCancellationRequiresNoCompletion(loanState) && (
                <CancelledLoanAlert loan={(loanState as SuccessState<Loan>).value} />
            )}
            {loanWithIQumulate && <IQumulateBanner />}
        </Box>
    );
}

const loanCancellationRequiresCompletion = (loanState: FetchState<Loan>): boolean => {
    return (
        isSuccess(loanState) &&
        loanState.value.cancellationRequest != null &&
        [LoanCancellationRequestStatus.UNDERPAID, LoanCancellationRequestStatus.OVERPAID].includes(
            loanState.value.cancellationRequest.status
        ) &&
        loanState.value.subStatus === LoanSubStatus.CANCELLED
    );
};

const loanCancellationRequiresNoCompletion = (loanState: FetchState<Loan>) => {
    return (
        isSuccess(loanState) &&
        loanState.value.cancellationRequest != null &&
        loanState.value.subStatus === LoanSubStatus.CANCELLED &&
        (loanState.value.balance === 0 ||
            loanState.value.cancellationRequest.status === LoanCancellationRequestStatus.COMPLETED)
    );
};
