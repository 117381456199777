import { DeleteOutline } from '@mui/icons-material';
import { Autocomplete, Box, FormControl, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { Controller } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { FieldArrayWithId, UseFieldArrayRemove } from 'react-hook-form/dist/types/fieldArray';
import { Control, UseFormRegister, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { ClassCode } from '../apis/invoiceConfig';
import { FetchState, FetchStateType } from '../hooks/useFetch';
import { BaseInvoiceFormFields } from '../pages/CreateInvoice/invoiceValidation';
import { useAppSelector } from '../store/reducer/Hooks';
import { DATE_COMPACT, DATE_SERVER_FORMAT, handleDatePickerChange } from '../util/dateUtils';

type Props = {
    control: Control<BaseInvoiceFormFields>;
    fields: FieldArrayWithId[];
    errors: FieldErrors<BaseInvoiceFormFields>;
    register: UseFormRegister<BaseInvoiceFormFields>;
    setValue: UseFormSetValue<BaseInvoiceFormFields>;
    remove: UseFieldArrayRemove;
    readonlyProvider?: boolean;
    readonlyClassCode?: boolean;
};

export default function PolicyDetailFields({
    fields,
    errors,
    control,
    register,
    setValue,
    remove,
    readonlyProvider,
    readonlyClassCode,
}: Readonly<Props>) {
    const { classCodesState, providersState } = useAppSelector((root) => root.SellerConfigReducer);

    const providerOnSelect = (val: string | null, index: number) => {
        setValue(`policies.${index}.provider`, val ?? '');
    };

    const classCodeOnSelect = (val: string | null, index: number) => {
        setValue(`policies.${index}.classCode`, val ?? '');
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            {fields.map((field, index) => {
                const error = errors.policies?.[index];
                const rowId = field.id;

                return (
                    <Box
                        key={rowId}
                        sx={{ display: 'flex', p: 1, pt: 2, border: `1px solid ${grey[400]}`, borderTop: 'none' }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <FormControl fullWidth required>
                                    <Controller
                                        control={control}
                                        name={`policies.${index}.number`}
                                        defaultValue=''
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                {...register(`policies.${index}.number`)}
                                                variant='standard'
                                                id={`number_${rowId}`}
                                                data-testid={`policies.${index}.number`}
                                                fullWidth
                                                autoComplete='no'
                                                size='small'
                                                error={!!error?.number}
                                                helperText={error?.number?.message}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={2}>
                                <FormControl fullWidth required>
                                    <Controller
                                        name={`policies.${index}.provider`}
                                        control={control}
                                        defaultValue=''
                                        render={({ field }) => (
                                            <Autocomplete
                                                {...field}
                                                {...register(`policies.${index}.provider`)}
                                                id={`provider_${rowId}`}
                                                data-testid={`policies.${index}.provider`}
                                                disablePortal
                                                fullWidth
                                                options={
                                                    providersState.type === FetchStateType.SUCCESS
                                                        ? providersState.value
                                                        : []
                                                }
                                                loading={providersState.type === FetchStateType.LOADING}
                                                size='small'
                                                onChange={(_event, value) => providerOnSelect(value, index)}
                                                readOnly={readonlyProvider}
                                                data-readonly={readonlyProvider}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant='standard'
                                                        error={!!error?.provider}
                                                        helperText={error?.provider?.message}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl fullWidth required>
                                    <Controller
                                        control={control}
                                        name={`policies.${index}.classCode`}
                                        defaultValue=''
                                        render={({ field }) => (
                                            <Autocomplete
                                                {...field}
                                                id={`classCode_${rowId}`}
                                                data-testid={`policies.${index}.classCode`}
                                                disablePortal
                                                fullWidth
                                                options={getClassCodes(classCodesState)}
                                                loading={classCodesState.type === FetchStateType.LOADING}
                                                size='small'
                                                onChange={(_event, value) => classCodeOnSelect(value, index)}
                                                readOnly={readonlyClassCode}
                                                data-readonly={readonlyClassCode}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant='standard'
                                                        error={!!error?.classCode}
                                                        helperText={error?.classCode?.message}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={2}>
                                <FormControl fullWidth required>
                                    <Controller
                                        name={`policies.${index}.currentPolicyVersion.anniversaryDate`}
                                        control={control}
                                        defaultValue={undefined}
                                        render={({ field }) => (
                                            <DatePicker
                                                onChange={handleDatePickerChange(field)}
                                                onAccept={handleDatePickerChange(field)}
                                                value={moment(field.value, DATE_SERVER_FORMAT)}
                                                inputRef={field.ref}
                                                slotProps={{
                                                    textField: {
                                                        id: `startDate_${rowId}`,
                                                        fullWidth: true,
                                                        size: 'small',
                                                        variant: 'standard',
                                                        helperText:
                                                            error?.currentPolicyVersion?.anniversaryDate?.message,
                                                        error: !!error?.currentPolicyVersion?.anniversaryDate,
                                                    },
                                                }}
                                                format={DATE_COMPACT}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={4}>
                                <FormControl fullWidth required>
                                    <Controller
                                        name={`policies.${index}.currentPolicyVersion.annualPremiums`}
                                        control={control}
                                        defaultValue={'' as unknown as number}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                {...register(`policies.${index}.currentPolicyVersion.annualPremiums`)}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                                                }}
                                                variant='standard'
                                                id={`premium_${rowId}`}
                                                data-testid={`policies.${index}.currentPolicyVersion.annualPremiums`}
                                                fullWidth
                                                size='small'
                                                error={!!error?.currentPolicyVersion?.annualPremiums}
                                                helperText={error?.currentPolicyVersion?.annualPremiums?.message}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Box width={40} sx={{ mt: -1 }}>
                            {fields.length > 1 && (
                                <IconButton
                                    onClick={() => remove(index)}
                                    color='error'
                                    data-testid={`policies.${index}.removePolicy`}
                                >
                                    <DeleteOutline />
                                </IconButton>
                            )}
                        </Box>
                    </Box>
                );
            })}
        </LocalizationProvider>
    );
}

const getClassCodes = (state: FetchState<ClassCode[]>): string[] => {
    return state.type === FetchStateType.SUCCESS ? state.value.map((c) => c.code) : [];
};
