import { Box, Paper, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import moment from 'moment';
import { InvoiceDetailsResponse, Policy } from '../../../apis/invoice';
import { SellerProduct } from '../../../apis/sellerProduct';
import Car from '../../../images/classCodeIcons/Car';
import Caravan from '../../../images/classCodeIcons/Caravan';
import Fire from '../../../images/classCodeIcons/Fire';
import Generic from '../../../images/classCodeIcons/Generic';
import Glass from '../../../images/classCodeIcons/Glass';
import Handcuffs from '../../../images/classCodeIcons/Handcuffs';
import Horse from '../../../images/classCodeIcons/Horse';
import House from '../../../images/classCodeIcons/House';
import Laptop from '../../../images/classCodeIcons/Laptop';
import Money from '../../../images/classCodeIcons/Money';
import Person from '../../../images/classCodeIcons/Person';
import Plane from '../../../images/classCodeIcons/Plane';
import Tractor from '../../../images/classCodeIcons/Tractor';
import { useAppSelector } from '../../../store/reducer/Hooks';
import { DATE_FRIENDLY } from '../../../util/dateUtils';
import { quoteableInvoice } from '../common';
import PolicyActionMenu from './PolicyActionMenu';

type Props = {
    policy: Policy;
    sellerProduct: SellerProduct;
    invoiceDetails: InvoiceDetailsResponse;
    sellerProductLogo?: string;
};

export default function PolicyDetail({ sellerProduct, invoiceDetails, policy, sellerProductLogo }: Readonly<Props>) {
    const { invoiceUpdateAllowed } = useAppSelector((root) => root.UserSessionReducer).permissions;

    return (
        <Paper
            variant='flat'
            sx={{ p: 2, display: 'flex', gap: 2, justifyContent: 'space-between', alignItems: 'center' }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                {sellerProductLogo ? (
                    <img src={sellerProductLogo} style={{ width: '60px', height: '60px' }} alt='Seller Product logo' />
                ) : (
                    <Box
                        sx={{
                            width: '60px',
                            height: '60px',
                            backgroundColor: grey[200],
                            borderRadius: '30px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {getPolicyImage(policy)}
                    </Box>
                )}
                <Box sx={{ flexDirection: 'column', display: 'flex', gap: 2 }}>
                    <Box>
                        <Typography variant='h6'>{policy.classCodeDescription ?? policy.classCode}</Typography>
                        <Typography variant='body2'>{policy.number}</Typography>
                    </Box>
                    <Box sx={{ flexDirection: 'column', display: 'flex' }}>
                        <Typography variant='caption'>
                            {moment(policy.currentPolicyVersion.anniversaryDate).format(DATE_FRIENDLY)} -{' '}
                            {moment(policy.currentPolicyVersion.endDate).format(DATE_FRIENDLY)}
                        </Typography>
                        <Typography variant='caption'>
                            {currencyFormatter.format(policy.currentPolicyVersion.annualPremiums)}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            {quoteableInvoice(sellerProduct, invoiceDetails.loan) && invoiceUpdateAllowed && (
                <Box>
                    <PolicyActionMenu policy={policy} sellerProduct={sellerProduct} invoiceDetails={invoiceDetails} />
                </Box>
            )}
        </Paper>
    );
}

const getPolicyImage = (policy: Policy) => {
    switch (policy.classCode) {
        case 'MV':
        case 'MVC':
        case 'MVN':
            return <Car />;

        case 'CVN':
            return <Caravan />;

        case 'BU':
        case 'CRI':
        case 'KI':
            return <Handcuffs />;

        case 'FI':
            return <Fire />;

        case 'AV':
        case 'AVN':
        case 'TR':
            return <Plane />;

        case 'THN':
            return <Horse />;

        case 'GL':
            return <Glass />;

        case 'MO':
        case 'BF':
        case 'CR':
        case 'CRN':
            return <Money />;

        case 'STC':
        case 'STR':
        case 'GP':
            return <House />;

        case 'PI':
        case 'PIN':
        case 'PA':
        case 'DO':
        case 'PL':
        case 'PLN':
            return <Person />;

        case 'CP':
        case 'CYB':
        case 'EIT':
        case 'EE':
            return <Laptop />;

        case 'RD':
        case 'RU':
        case 'RUN':
        case 'CRP':
            return <Tractor />;

        default:
            return <Generic />;
    }
};

const currencyFormatter = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
