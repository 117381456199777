import { InvoiceDetailsResponse } from '../../../apis/invoice';
import { PolicyVersionStatus } from '../../../apis/quotes';
import { SnackState } from '../../../components/SnackAlert';
import { useAppSelector } from '../../../store/reducer/Hooks';
import InvoiceActions from './InvoiceActions';
import InvoicePostPaidActions from './InvoicePostPaidActions';
import RenewalActions from './RenewalActions';

type Props = {
    invoiceDetails: InvoiceDetailsResponse;
    approvalRequestSentCount: number;
    setApprovalRequestSentCount: (count: number) => void;
    handleSetSnack: (state: SnackState) => void;
};

export default function InvoiceActionButton({
    invoiceDetails,
    approvalRequestSentCount,
    setApprovalRequestSentCount,
    handleSetSnack,
}: Readonly<Props>) {
    const {
        permissions: { invoiceUpdateAllowed },
    } = useAppSelector((state) => state.UserSessionReducer);

    if (!invoiceUpdateAllowed) {
        return null;
    }

    if (
        invoiceDetails.invoice.portfolio.policies[0].policyVersions.some((version) =>
            [PolicyVersionStatus.PENDING_RENEWAL, PolicyVersionStatus.READY_FOR_RENEWAL].includes(version.status)
        )
    ) {
        return (
            <RenewalActions
                setSnack={handleSetSnack}
                approvalRequestSentCount={approvalRequestSentCount}
                setApprovalRequestSentCount={setApprovalRequestSentCount}
                invoiceDetails={invoiceDetails}
            />
        );
    }

    if (!invoiceDetails.invoice.paidDate) {
        return (
            <InvoiceActions
                setSnack={handleSetSnack}
                approvalRequestSentCount={approvalRequestSentCount}
                setApprovalRequestSentCount={setApprovalRequestSentCount}
                invoice={invoiceDetails.invoice}
                client={invoiceDetails.client}
            />
        );
    }

    if (!invoiceDetails.loan) {
        return null;
    }

    return <InvoicePostPaidActions invoiceDetails={invoiceDetails} />;
}
