import { Box, Divider, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { InvoiceDetailsResponse, Loan } from '../../apis/invoice';
import { getSellerProduct, SellerProduct } from '../../apis/sellerProduct';
import Communications from '../../components/Communications';
import { ExtendableFetchWrapper } from '../../components/ExtendableFetchWrapper';
import { isSuccess, SuccessState, useFetch } from '../../hooks/useFetch';
import { BREAKPOINT } from '../../store/reducer/BreakpointReducer';
import { useAppSelector } from '../../store/reducer/Hooks';
import ArrangementActions from './Arrangements/Actions';
import InvoiceEndorsements from './Endorsements/InvoiceEndorsements';
import InvoiceNotes from './InvoiceNotes';
import OverviewCards from './OverviewCards';
import Payments from './Payments';
import Policies, { PoliciesProps } from './Policies';
import RepaymentTerms from './Policies/RepaymentTerms';
import { quoteableInvoice } from './common';
import Quotes from '../../components/Quotes';

type Props = {
    invoiceDetails: InvoiceDetailsResponse;
    onApprovalRequestSent: () => void;
};

export default function InvoiceBreakdown({ invoiceDetails, onApprovalRequestSent }: Readonly<Props>) {
    const {
        permissions: { accountAllowed },
    } = useAppSelector((state) => state.UserSessionReducer);
    const { state: loanState } = useAppSelector((root) => root.LoanReducer);
    const { down } = useAppSelector((state) => state.BreakpointReducer);
    const [tab, setTab] = useState(0);
    const canSeePayments = accountAllowed && isSuccess(loanState) && loanState.value != null;
    const sellerProductState = useFetch(() => getSellerProduct(invoiceDetails.invoice.sellerProductIdentifier));
    const unpaid = !invoiceDetails.invoice.paidDate;
    const canSeeQuotes =
        isSuccess(sellerProductState) && quoteableInvoice(sellerProductState.value, invoiceDetails.loan);

    const handleTabChange = (_e: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    return (
        <Box display='block'>
            <Box sx={{ display: 'flex', gap: 2, flexDirection: { xs: 'column', md: 'row' } }}>
                <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
                    <Box>
                        <Tabs
                            value={tab}
                            onChange={handleTabChange}
                            TabIndicatorProps={{ sx: { display: 'none' } }}
                            sx={{
                                '& .MuiTabs-flexContainer': {
                                    flexWrap: 'wrap',
                                },
                            }}
                        >
                            <Tab label='Policies' />
                            <Tab label='Payments' disabled={!canSeePayments && !unpaid} />
                            <Tab label='Quotes' disabled={!canSeeQuotes} />
                            <Tab label='Endorsements' disabled={unpaid} />
                            <Tab label='Notes' />
                            <Tab label='Communications' />
                        </Tabs>
                    </Box>
                    <Divider />
                    <Box
                        sx={{
                            display: 'flex',
                            py: 4,
                            gap: 2,
                            flexDirection: 'column',
                            maxWidth: { sm: 'calc(100vw - 320px)', md: 'calc(100vw - 636px)' },
                        }}
                    >
                        {tab === 0 && (
                            <ExtendableFetchWrapper<SellerProduct, PoliciesProps>
                                state={sellerProductState}
                                SuccessComponent={Policies}
                                invoiceDetails={invoiceDetails}
                            />
                        )}
                        {tab === 1 && (
                            <>
                                {canSeePayments && (
                                    <>
                                        <Payments data={(loanState as SuccessState<Loan>).value} />
                                        <ArrangementActions
                                            loan={(loanState as SuccessState<Loan>).value}
                                            invoiceUuid={invoiceDetails.invoice.uuid}
                                        />
                                    </>
                                )}

                                {unpaid && (
                                    <RepaymentTerms
                                        invoiceDetails={invoiceDetails}
                                        onApprovalRequestSent={onApprovalRequestSent}
                                    />
                                )}
                            </>
                        )}
                        {tab === 2 && isSuccess(sellerProductState) && (
                            <Quotes
                                policies={invoiceDetails.invoice.portfolio.policies}
                                invoiceDetails={invoiceDetails}
                                sellerProduct={sellerProductState.value}
                            />
                        )}
                        {tab === 3 && (
                            <InvoiceEndorsements
                                endorsements={invoiceDetails.invoice.endorsements}
                                policy={invoiceDetails.invoice.portfolio.policies[0]}
                            />
                        )}
                        {tab === 4 && <InvoiceNotes invoiceIdentifier={invoiceDetails.invoice.uuid} />}
                        {tab === 5 && <Communications invoiceIdentifier={invoiceDetails.invoice.uuid} />}
                    </Box>
                </Box>
                {down(BREAKPOINT.md) && <Divider />}
                <OverviewCards invoiceDetails={invoiceDetails} />
            </Box>
        </Box>
    );
}
