import { ArrowUpward, SwapVert } from '@mui/icons-material';
import { Box } from '@mui/material';
import { grey } from '@mui/material/colors';

export default function SortIcons() {
    return (
        <Box sx={{ display: 'flex' }}>
            <Box sx={{ color: grey[600] }}>
                <ArrowUpward className='selectedSort' />
            </Box>
            <Box sx={{ color: grey[400] }}>
                <SwapVert className='unselectedSort' />
            </Box>
        </Box>
    );
}
