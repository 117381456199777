import { useEffect, useState } from 'react';
import { Alert, AlertColor, Box, Divider, Drawer, Grid, Snackbar, Typography } from '@mui/material';
import ToSection from './ToSection';
import PortfolioDetails from './PortfolioDetails';
import { Save } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import moment from 'moment/moment';
import { LoadingButton } from '../../components/LoadingButton';
import { saveInvoice } from '../../apis/invoice';
import { hasPolicyDurationIssue } from './hasPolicyDurationIssue';
import { Client } from '../../apis/clients';
import CreateClient from '../CreateClient';
import {
    BaseInvoiceFormFields,
    baseSchema,
    getBaseInvoiceRequest,
    getFriendlyErrorMsg,
    tallyPremiums,
} from './invoiceValidation';

export type InvoiceFormFields = BaseInvoiceFormFields & {
    dueDate: string;
    clientIdentifier: string;
};

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});

const schema = baseSchema().concat(
    yup.object({
        dueDate: yup.date().required('Due date required').min(moment().format('YYYY-MM-DD')),
        clientIdentifier: yup.string().required('Client required'),
    })
);

type InvoiceFormProps = {
    client?: Client;
    setClient: (client: Client | undefined) => void;
};

const InvoiceForm = ({ client, setClient }: InvoiceFormProps) => {
    const navigate = useNavigate();
    const [openCreateClient, setOpenCreateClient] = useState(false);
    const [saving, setSaving] = useState(false);
    const [snackbar, setSnackbar] = useState<{ msg: string; severity: AlertColor }>();
    const [uuid, setUuid] = useState<string>();

    const {
        handleSubmit,
        control,
        register,
        formState: { errors },
        setValue,
    } = useForm<InvoiceFormFields>({
        resolver: yupResolver(schema),
        defaultValues: {
            dueDate: moment().format('YYYY-MM-DD'),
        },
    });

    const { fields, append } = useFieldArray({
        control,
        name: 'policies',
    });

    const policies = useWatch({ control, name: 'policies' });
    const [totalPremiums, setTotalPremiums] = useState(0);

    useEffect(() => {
        if (uuid) {
            navigate('/invoices/' + uuid);
        }
    }, [navigate, uuid]);

    useEffect(() => {
        setTotalPremiums(tallyPremiums(policies));
        checkPolicyLength();
    }, [policies]);

    useEffect(() => {
        setValue('clientIdentifier', client?.uuid ?? '', { shouldValidate: false });
    }, [client]);

    const checkPolicyLength = () => {
        if (hasPolicyDurationIssue(policies)) {
            setSnackbar({
                msg: 'The duration is much shorter than the total contract time. Consider splitting these policies for better alignment.',
                severity: 'warning',
            });
        }
    };

    const onSubmit: SubmitHandler<InvoiceFormFields> = (data) => {
        setSaving(true);

        const invoice = {
            ...getBaseInvoiceRequest(data),
            dueDate: moment(data.dueDate).format('YYYY-MM-DD'),
            clientIdentifier: data.clientIdentifier,
        };

        saveInvoice(invoice)
            .then((response) => {
                setUuid(response.uuid);
            })
            .catch((e) => {
                setSnackbar({
                    msg: getFriendlyErrorMsg(e),
                    severity: 'error',
                });
            })
            .finally(() => setSaving(false));
    };

    const selectNewClient = (client: Client) => {
        setClient(client);
        setOpenCreateClient(false);
    };

    const handleCloseErrorSnackbar = () => {
        setSnackbar(undefined);
    };

    return (
        <>
            <Snackbar
                open={snackbar != null}
                autoHideDuration={10_000}
                onClose={handleCloseErrorSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseErrorSnackbar} severity={snackbar?.severity} sx={{ width: '100%' }}>
                    {snackbar?.msg}
                </Alert>
            </Snackbar>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ToSection
                    control={control}
                    selectClient={setClient}
                    errors={errors}
                    client={client}
                    setOpenSection={setOpenCreateClient}
                />
                <PortfolioDetails
                    fields={fields}
                    append={append}
                    control={control}
                    errors={errors}
                    register={register}
                    setValue={setValue}
                />
                <Grid container spacing={2}>
                    <Grid item xs={9}></Grid>
                    <Grid item xs={3} sx={{ p: 2 }}>
                        <Divider />
                        <Grid container>
                            <Grid item xs={6}>
                                <Typography variant='h6' sx={{ fontWeight: 600 }}>
                                    Total:{' '}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                <Typography variant='h1' component='p'>
                                    {currencyFormat.format(totalPremiums)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Box sx={{ width: '100%', textAlign: 'right' }}>
                    <LoadingButton
                        type='submit'
                        variant='contained'
                        color='primary'
                        startIcon={<Save />}
                        loading={saving}
                    >
                        Save invoice
                    </LoadingButton>
                </Box>
            </form>
            <Drawer
                anchor='right'
                open={openCreateClient}
                onClose={() => {
                    setOpenCreateClient(false);
                }}
                PaperProps={{
                    sx: { width: '50%', maxWidth: '1200px' },
                }}
            >
                <CreateClient callback={selectNewClient} />
            </Drawer>
        </>
    );
};

export default InvoiceForm;
