import { Box, Checkbox, Collapse, FormControlLabel, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Client } from '../../../apis/clients';
import { ActionType } from '../common';
import Actions from './Actions';

type Props = {
    selectedAction: ActionType;
    handleSelectAction: (actionType: ActionType) => void;
    client: Client;
    alreadyQuoted: boolean;
    confirmed: boolean;
    handleCheckConfirmation: (confirmed: boolean) => void;
};

export default function SelectAction({
    selectedAction,
    handleSelectAction,
    client,
    alreadyQuoted,
    handleCheckConfirmation,
    confirmed,
}: Readonly<Props>) {
    return (
        <Box>
            <Typography variant='h2' pb={0} mb={2}>
                Select next action
            </Typography>
            <Actions
                selectedAction={selectedAction}
                setSelectedAction={handleSelectAction}
                client={client}
                alreadyQuoted={alreadyQuoted}
            />
            <Collapse in={selectedAction === ActionType.CREATE_ENDORSEMENT}>
                <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: grey[100], p: 2, mt: 4 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id='confirm-endorsement'
                                onChange={(event) => handleCheckConfirmation(event.target.checked)}
                                size='small'
                                checked={confirmed}
                            />
                        }
                        label='I confirm that the policyholder is aware of the endorsement adjustment and they have agreed to the new premium amount.'
                    />
                </Box>
            </Collapse>
        </Box>
    );
}
