import {
    Alert,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    Grid,
    Paper,
    Typography,
    useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useRef, useState } from 'react';
import { PaymentMethodType } from '../../../../apis/clientLead';
import { EndorsementPolicyStatus } from '../../../../apis/endorsement';
import { CalculatedOn, InvoiceDetailsResponse, Loan } from '../../../../apis/invoice';
import {
    addOndemandPayment,
    isSuccessfulPaymentResponse,
    OndemandPaymentRequest,
    OndemandPaymentType,
    PaymentResponse,
    PaymentStatus,
    queryPaymentResult,
} from '../../../../apis/variations';
import { LoadingButton } from '../../../../components/LoadingButton';
import StepCard from '../../../../components/StepCard';
import PaymentMethodDetails from '../../../MatchClientLeads/ClientLeadCell/PaymentMethodDetails';
import { StyledGridHeaderContainer, StyledGridItemContainer } from '../styled';
import { OndemandPaymentFields } from '../types';
import { getInvoicePolicyDescription } from '../utils';

type Props = {
    cancel: () => void;
    handleBack: () => void;
    setLoan: (loan: Loan) => void;
    ondemandPaymentFields: OndemandPaymentFields;
    loan: Loan;
    details: InvoiceDetailsResponse;
    overdueAmount: number;
};

const MAX_POLLS = 30;
const POLLING_TIME = 500;

export default function OndemandPaymentReviewStep({
    cancel,
    handleBack,
    setLoan,
    ondemandPaymentFields,
    loan,
    details,
    overdueAmount,
}: Readonly<Props>) {
    const [errorMsg, setErrorMsg] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const pollingRef = useRef(0);

    const theme = useTheme();

    const futureEndorsementAdjustment =
        details.invoice.endorsements
            ?.flatMap(({ policyEndorsements }) => policyEndorsements ?? [])
            .filter(({ status }) => status === EndorsementPolicyStatus.ACCEPTED)
            .map(({ premiumsAdjustment, instalmentFeeAdjustment }) => premiumsAdjustment + instalmentFeeAdjustment)
            .reduce((total, policyEndorsement) => total + policyEndorsement, 0) ?? 0;

    const cancellationSkippedPremiums = loan.cancellationRequest?.cancellationSkippedPremiums ?? 0;
    const outstandingSettlementAmount =
        (loan.outstandingSettlementAmount ?? 0) + futureEndorsementAdjustment - cancellationSkippedPremiums;

    const amount =
        ondemandPaymentFields.paymentType === OndemandPaymentType.OUTSTANDING
            ? outstandingSettlementAmount
            : ondemandPaymentFields.amount ?? overdueAmount;
    const feeAmount =
        ondemandPaymentFields.paymentConfiguration?.transactionFeeCharges
            .map((fee) =>
                fee.calculatedOn === CalculatedOn.FIXED ? fee.chargeValue : amount * (fee.chargeValue / 100)
            )
            .reduce((a, b) => a + b, 0) ?? 0;
    const total = amount + feeAmount;
    const overdueRemaining =
        ondemandPaymentFields.paymentType === OndemandPaymentType.OVERDUE ? overdueAmount - amount : 0;

    const onConfirmation = () => {
        setLoading(true);

        const ondemandPaymentRequest: OndemandPaymentRequest = {
            amount: ondemandPaymentFields.amount,
            paymentType: ondemandPaymentFields.paymentType,
            note: ondemandPaymentFields.note,
        };
        addOndemandPayment(details.invoice.uuid, ondemandPaymentRequest).then(handleResponse).catch(handleError);
    };

    const handleError = (error?: string) => {
        pollingRef.current = 0;
        setConfirmed(false);
        setLoading(false);
        setErrorMsg(error);
    };

    const handleResponse = (res: PaymentResponse) => {
        if (isSuccessfulPaymentResponse(res)) {
            setLoan(res.loan);
            setLoading(false);
            return;
        }

        if (res.status === PaymentStatus.PROCESSING) {
            ++pollingRef.current;
            if (pollingRef.current > MAX_POLLS) {
                return handleError(
                    'The payment response is taking too long to load. Please come back and try again later.'
                );
            }

            setTimeout(() => {
                queryPaymentResult(details.invoice.uuid, res.uuid).then(handleResponse).catch(setErrorMsg);
            }, POLLING_TIME);
            return;
        }

        if (res.status === PaymentStatus.PAYMENT_FAILED) {
            return handleError(
                'The payment has failed. Ensure the client has sufficient funds available and try again.'
            );
        }

        return handleError();
    };

    if (loading) {
        return (
            <StepCard>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                        <CircularProgress color='primary' size={32} data-testid='loadingSpinner' />
                        <Typography variant='h5'>Payment processing</Typography>
                        <Typography variant='body1'>Please wait. This may take a few seconds.</Typography>
                    </Box>
                </Box>
            </StepCard>
        );
    }

    return (
        <StepCard>
            <Typography variant='h5' component='h2'>
                Review payment details
            </Typography>
            {errorMsg && (
                <Alert severity='error'>
                    <Typography style={{ fontWeight: 500, display: 'inherit', marginBottom: 3 }}>
                        Payment Failed
                    </Typography>
                    {errorMsg}
                </Alert>
            )}
            <Box>
                <Typography variant='body1'>Payment</Typography>
                <Typography variant='caption'>{`For ${details.client.displayName}'s insurance #${details.invoice.number} (${getInvoicePolicyDescription(details.invoice)})`}</Typography>
            </Box>
            <Box sx={{ display: 'inline-flex' }}>
                <Typography variant='body1' sx={{ mr: 1 }}>
                    Payment type:
                </Typography>
                <Typography variant='caption'>Charge client’s payment method</Typography>
            </Box>

            <Box>
                <Grid
                    container
                    sx={{
                        border: grey[400],
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderRadius: `${theme.shape.borderRadius}px`,
                    }}
                >
                    <StyledGridHeaderContainer>
                        <Grid item xs={6} pr={2}>
                            <Typography variant='subtitle2'>Transaction type</Typography>
                        </Grid>
                        <Grid item xs={6} textAlign={'right'}>
                            <Typography variant='subtitle2'>Amount</Typography>
                        </Grid>
                    </StyledGridHeaderContainer>
                    <StyledGridItemContainer>
                        <Grid item xs={6}>
                            <Typography variant='caption'>
                                {ondemandPaymentFields.paymentType === OndemandPaymentType.OUTSTANDING
                                    ? 'Oustanding'
                                    : 'Overdue'}{' '}
                                balance
                            </Typography>
                        </Grid>
                        <Grid item xs={6} textAlign={'right'}>
                            <Typography variant='caption'>{currencyFormat.format(amount)}</Typography>
                        </Grid>
                    </StyledGridItemContainer>
                    <StyledGridItemContainer>
                        <Grid item xs={6}>
                            <Typography variant='caption'>Transaction fee</Typography>
                        </Grid>
                        <Grid item xs={6} textAlign={'right'}>
                            <Typography variant='caption'>{currencyFormat.format(feeAmount)}</Typography>
                        </Grid>
                    </StyledGridItemContainer>
                    <StyledGridItemContainer>
                        <Grid item xs={6}>
                            <Typography variant='subtitle2'>Total</Typography>
                        </Grid>
                        <Grid item xs={6} textAlign={'right'}>
                            <Typography variant='subtitle2'>{currencyFormat.format(total)}</Typography>
                        </Grid>
                    </StyledGridItemContainer>
                </Grid>
            </Box>
            {ondemandPaymentFields.paymentConfiguration && (
                <Paper variant='outlined' sx={{ p: 2, gap: 1 }}>
                    <Typography color={theme.palette.black[100]} sx={{ pb: 1 }}>
                        Client's payment method
                    </Typography>
                    <PaymentMethodDetails paymentMethod={ondemandPaymentFields.paymentConfiguration.paymentMethod} />
                </Paper>
            )}

            <Box sx={{ display: 'inline-flex' }}>
                <Typography variant='body1' sx={{ mr: 1 }}>
                    Note:
                </Typography>
                <Typography variant='caption'>{ondemandPaymentFields.note}</Typography>
            </Box>
            <Typography variant='body1'>{`After this payment, the client will have ${currencyFormat.format(overdueRemaining)} overdue remaining and ${currencyFormat.format(outstandingSettlementAmount - amount)} outstanding on their invoice.`}</Typography>

            {ondemandPaymentFields?.paymentConfiguration?.paymentMethod.type === PaymentMethodType.DIRECT_DEBIT && (
                <Typography variant='body1'>{`The payment will be be deducted within the next 24 hours. The client will need to have ${currencyFormat.format(total)} available in their account.`}</Typography>
            )}

            <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: grey[100], p: 2 }}>
                <FormControlLabel
                    control={
                        <Checkbox id='confirm-payment-holiday' onChange={() => setConfirmed(!confirmed)} size='small' />
                    }
                    label={
                        <Typography variant='body1'>
                            I confirm that the client has agreed to be charged {currencyFormat.format(total)} using the
                            above payment method.
                        </Typography>
                    }
                />
            </Box>
            <Typography variant='caption'>{`${details.client.displayName} will receive an email notification with the payment details.`}</Typography>

            <Grid container>
                <Grid item xs={6}>
                    <Button onClick={handleBack} variant='outlined'>
                        Back
                    </Button>
                </Grid>
                <Grid item xs={6} container direction='row' justifyContent='flex-end' alignItems='center'>
                    <Button onClick={cancel} variant='text' size='large' sx={{ mr: 1 }}>
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={loading}
                        onClick={onConfirmation}
                        disabled={!confirmed}
                        variant='contained'
                        size='large'
                        sx={{ minWidth: '160px' }}
                    >
                        {`Charge ${currencyFormat.format(total)}`}
                    </LoadingButton>
                </Grid>
            </Grid>
        </StepCard>
    );
}

const currencyFormat = new Intl.NumberFormat('en-nz', {
    style: 'currency',
    currency: 'NZD',
});
