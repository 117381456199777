import { Checkbox, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import { upperFirst } from 'lodash';
import { EnquiryStatus } from '../../../apis/enquiry';

type Props = {
    enquiryStatuses: EnquiryStatus[];
    setEnquiryStatuses: (enquiryTypes: EnquiryStatus[]) => void;
};

export default function EnquiryStatusSelect({ enquiryStatuses, setEnquiryStatuses }: Readonly<Props>) {
    const handleEnquiryStatusesChange = (event: SelectChangeEvent<typeof enquiryStatuses>) => {
        const {
            target: { value },
        } = event;
        const enquiryStatusSelected = (typeof value === 'string' ? value.split(',') : value) as EnquiryStatus[];
        setEnquiryStatuses(enquiryStatusSelected.length === 0 ? Object.values(EnquiryStatus) : enquiryStatusSelected);
    };

    return (
        <Select
            multiple
            value={enquiryStatuses}
            onChange={handleEnquiryStatusesChange}
            input={<OutlinedInput id='enquiry-status-multiple-checkbox' />}
            renderValue={(selected: EnquiryStatus[]) =>
                renderValues(selected, Object.values(EnquiryStatus), getFriendlyEnquiryStatus)
            }
        >
            {Object.values(EnquiryStatus).map((type) => (
                <MenuItem key={type} value={type}>
                    <Checkbox checked={enquiryStatuses.includes(type)} />
                    <ListItemText primary={getFriendlyEnquiryStatus(type)} />
                </MenuItem>
            ))}
        </Select>
    );
}

const renderValues = (selected: EnquiryStatus[], values: EnquiryStatus[], transform: (t: EnquiryStatus) => string) => {
    if (selected.length === values.length || selected.length === 0) {
        return 'Any';
    }

    return selected.map(transform).join(', ');
};

const getFriendlyEnquiryStatus = (enquiryStatus: EnquiryStatus): string => {
    return upperFirst(enquiryStatus.toLowerCase().replace('_', ' '));
};
