import { Chip } from '@mui/material';
import { EnquiryStatus } from '../apis/enquiry';

type Props = {
    status: EnquiryStatus;
};

export default function EnquiryStatusChip({ status }: Readonly<Props>) {
    switch (status) {
        case EnquiryStatus.IN_PROGRESS:
            return <Chip label='In progress' color='info' variant='outlined' />;
        case EnquiryStatus.NEW:
            return <Chip label='New' variant='outlined' />;
        case EnquiryStatus.ON_HOLD:
            return <Chip label='On hold' color='warning' variant='outlined' />;
        case EnquiryStatus.SPAM:
            return <Chip label='Spam' variant='outlined' />;
        case EnquiryStatus.CLOSED:
            return <Chip label='Closed' color='success' variant='outlined' />;
        default:
            return <Chip label='No Status' variant='outlined' />;
    }
}
