import { cloneDeep } from 'lodash';
import moment from 'moment';
import { FieldPath, UseFormSetValue } from 'react-hook-form';
import * as yup from 'yup';
import { Invoice, Policy } from '../../apis/invoice';
import {
    BeneficiaryRequest,
    ItemRequest,
    ItemType,
    PolicyVersionReason,
    PolicyVersionRequest,
    QuoteReason,
    QuoteStatus,
} from '../../apis/quotes';
import { SellerProduct } from '../../apis/sellerProduct';
import { asString, DATE_FRIENDLY, DATE_SERVER_FORMAT, invalidDate } from '../../util/dateUtils';

// form field types
export type QuoteFields = {
    items: ItemFormFields[];
    effectiveDate: string;
};

export type ItemFormFields = {
    covers: CoverFields[];
    beneficiaries: BeneficiaryRequest[];
    questionnaire: Record<string, FormDetail>;
    name: string;
    itemCoverStartDate: string;
};

type CoverFields = {
    fixedCoverage: boolean;
    coverageAmount: number;
    productCoverIdentifier: string;
    productCoverName: string;
    productCoverCode: string;
};

type BaseFormDetail = {
    required: boolean;
    label: string;
    name: string;
    className?: string;
    description?: string;
    placeholder?: string;
    value?: string;
    inputValue?: string;
    errorMsg?: string;
};

type NumberFormDetail = BaseFormDetail & {
    type: 'number';
    min?: number;
    max?: number;
};

type RadioFormDetail = BaseFormDetail & {
    type: 'radio-group';
    values: {
        label: string;
        value: string;
        selected: boolean;
    }[];
};

export type DateFormDetail = BaseFormDetail & {
    type: 'date';
};

export type FormDetail = NumberFormDetail | RadioFormDetail | DateFormDetail;

// validation
type FromContext<T> = {
    from: { value: T }[];
};

export const getSchema = (minEffectiveDate: moment.Moment, maxEffectiveDate: moment.Moment) => {
    return yup.object({
        effectiveDate: yup
            .date()
            .required('Effective date is required')
            .nullable()
            .transform((curr) => (invalidDate(curr) ? null : curr))
            .test(
                'checkDate',
                `Effective date must be between ${minEffectiveDate.format(DATE_FRIENDLY)} and ${maxEffectiveDate.format(DATE_FRIENDLY)}`,
                (value) => {
                    if (invalidDate(value)) {
                        return false;
                    }

                    const date = moment(value);
                    return (
                        date.isSameOrAfter(asString(minEffectiveDate)) &&
                        date.isSameOrBefore(asString(maxEffectiveDate))
                    );
                }
            ),
        items: yup.array().of(itemSchema).min(1),
    });
};

export const beneficiarySchema = yup.object({
    name: yup.string().required('Name is required'),
    dateOfBirth: yup
        .date()
        .nullable()
        .transform((curr, rawValue) => {
            if (!rawValue) {
                return null;
            }

            return curr;
        })
        .typeError('Must be a valid date')
        .test('validDate', 'Must be a valid date', (value) => {
            if (!value) {
                return true;
            }

            if (invalidDate(value)) {
                return false;
            }

            return moment(value).isSameOrBefore(asString(moment()));
        }),
    emailAddress: yup.string().email('Must be a valid email address').nullable(),
    address: yup.string().nullable(),
    relationshipToPolicyHolder: yup.string().nullable(),
    contactNumber: yup.string().nullable(),
    distribution: yup
        .number()
        .typeError('Number is required')
        .required()
        .test('distributionSum', `Distributions must be less than 100`, (_value, context: yup.TestContext) => {
            if (!context.path.includes('beneficiaries')) {
                return true;
            }
            const allBeneficiaries = (context as unknown as FromContext<ItemFormFields>).from[1].value.beneficiaries;
            const sum = allBeneficiaries
                .reduce((a, beneficiary) => {
                    let asFloat = parseFloat(beneficiary.distribution as unknown as string);
                    if (Number.isNaN(asFloat)) {
                        asFloat = 0;
                    }
                    return a + asFloat;
                }, 0)
                .toFixed(2);
            return parseFloat(sum) <= 100;
        })
        .min(0.01)
        .max(100),
});

export const itemSchema = yup.object({
    covers: yup
        .array()
        .of(
            yup.object({
                coverageAmount: yup.number().typeError('Number is required').min(0),
            })
        )
        .test('at least one cover', 'At least one cover required', (value) => {
            return value?.some((cover) => (cover.coverageAmount ?? 0) > 0) ?? false;
        }),
    beneficiaries: yup.array().of(beneficiarySchema).min(1),
    name: yup
        .string()
        .required('Name is required')
        .test('uniqueName', 'Item name must be unique', (value, context: yup.TestContext) => {
            if (!context.path.includes('items')) {
                return true;
            }
            const allItems = (context as unknown as FromContext<QuoteFields>).from[1].value.items;
            return allItems.filter((item: ItemFormFields) => item.name === value).length === 1;
        }),
});

/**
 * Validate the questionnaire without yup.
 * Yup and react-hook-form cannot work together to handle configurable forms
 * react-hook-form is necessary to get all the data on submit, so yup is sacrificed
 */
export const findInputErrors = (
    data: QuoteFields,
    setValue: UseFormSetValue<QuoteFields>
): FieldPath<QuoteFields>[] => {
    const errors: FieldPath<QuoteFields>[] = [];
    data.items?.forEach((item, index) => {
        Object.entries(item.questionnaire).forEach(([name, formDetail]) => {
            const inError = findInputError(name, index, formDetail, setValue);
            if (inError) {
                errors.push(inError);
            }
        });
    });

    return errors;
};

const findInputError = (
    name: string,
    index: number,
    formDetail: FormDetail,
    setValue: UseFormSetValue<QuoteFields>
): FieldPath<QuoteFields> | undefined => {
    const fieldName: FieldPath<QuoteFields> = `items.${index}.questionnaire.${name}`;
    const error = (errorMsg: string): FieldPath<QuoteFields> => {
        setValue(fieldName, { ...formDetail, errorMsg });
        return `${fieldName}.value`;
    };
    const clearErrors = () => {
        setValue(fieldName, { ...formDetail, errorMsg: undefined });
        return undefined;
    };

    switch (formDetail.type) {
        case 'date':
            return validateDate(formDetail, error, clearErrors);
        case 'number':
            return validateNumber(formDetail, error, clearErrors);
        case 'radio-group':
            return validateGroup(formDetail, error, clearErrors);
        default:
            return clearErrors();
    }
};

type SetErrorFn = (error: string) => FieldPath<QuoteFields>;
type ClearErrorFn = () => undefined;

function validateDate(detail: DateFormDetail, setError: SetErrorFn, clearErrors: ClearErrorFn) {
    const { value, required, label } = detail;
    if (!value) {
        if (required) {
            return setError(`${label} is required`);
        } else {
            return clearErrors();
        }
    }
    if (!moment(value).isValid()) {
        return setError(`${label} must be a valid date`);
    }

    clearErrors();
}

function validateNumber(detail: NumberFormDetail, setError: SetErrorFn, clearErrors: ClearErrorFn) {
    const { value, min, max, required, label } = detail;
    if (!value) {
        if (required) {
            return setError(`${label} is required`);
        } else {
            return clearErrors();
        }
    }

    const asFloat = parseFloat(value || '');
    if (Number.isNaN(asFloat)) {
        return setError('Number is required');
    }

    if (min && asFloat <= min) {
        return setError(`Must be greater than ${min - 1}`);
    }

    if (max && asFloat >= max) {
        return setError(`Must be less than ${max + 1}`);
    }
    clearErrors();
}

function validateGroup(detail: RadioFormDetail, setError: SetErrorFn, clearErrors: ClearErrorFn) {
    if (detail.required && !detail.value) {
        return setError(`${detail.label} is required`);
    }
    clearErrors();
}

// empty objects
export const newBeneficiary = (): BeneficiaryRequest => {
    return {
        name: '',
        distribution: '' as unknown as number,
    };
};

export const getEmptyQuote = (sellerProduct: SellerProduct): QuoteFields => {
    return {
        effectiveDate: moment().format(DATE_SERVER_FORMAT),
        items: [getEmptyItem(sellerProduct)],
    };
};

export const getEmptyItem = (sellerProduct: SellerProduct): ItemFormFields => {
    return {
        name: '',
        covers: sellerProduct
            .covers!.filter((cover) => cover.enabled)
            .map((cover) => ({
                coverageAmount: 0,
                productCoverIdentifier: cover.uuid,
                productCoverName: cover.name,
                productCoverCode: cover.code,
                fixedCoverage: cover.fixedCoverage,
            })),
        questionnaire: getEmptyQuestionnaireFields(sellerProduct),
        beneficiaries: [newBeneficiary()],
        itemCoverStartDate: '',
    };
};

const getEmptyQuestionnaireFields = (sellerProduct: SellerProduct): Record<string, FormDetail> => {
    const questionnaireFields: Record<string, FormDetail> = {};
    const formDetails: FormDetail[] = JSON.parse(sellerProduct.questionnaire?.formDetails ?? '[]');
    formDetails.forEach((input) => {
        questionnaireFields[input.name] = input;
        if (input.type === 'radio-group') {
            input.value = input.values.find((radio) => radio.selected)?.value;
        }
        input.value = input.value ?? '';
    });
    return questionnaireFields;
};

// casting
type ItemUnion = ItemFormFields & ItemRequest;
export const castPolicyVersionToFormFields = (
    sellerProduct: SellerProduct,
    policyVersionRequest: PolicyVersionRequest
): QuoteFields => {
    const clone = cloneDeep(policyVersionRequest);

    if (!clone.items?.length) {
        clone.items = [getEmptyItem(sellerProduct) as unknown as ItemRequest];
    } else {
        // assign underwriting details to questionnaire
        const questionnaire = getEmptyQuestionnaireFields(sellerProduct);
        (clone.items as ItemUnion[]).forEach((item) => {
            // set the values of the questionnaire if underwriting details exist
            item.questionnaire = cloneDeep(questionnaire);
            if (item.underwritingDetails != null) {
                const answers: Record<string, string> = JSON.parse(item.underwritingDetails?.answers ?? '{}');
                Object.keys(answers).forEach((key) => {
                    if (item.questionnaire[key] && answers[key]) {
                        item.questionnaire[key].value = answers[key];
                    }
                });
            }
            item.beneficiaries.forEach((beneficiary) => (beneficiary.distribution = beneficiary.distribution * 100));
        });
    }

    return clone as unknown as QuoteFields;
};

export const castFormFieldsToRequest = (
    data: QuoteFields,
    sellerProduct: SellerProduct,
    invoice: Invoice,
    policy: Policy
): PolicyVersionRequest => {
    return {
        ...data,
        quote: {
            reason: QuoteReason.ENDORSEMENT,
            insuredIdentifier: invoice.clientIdentifier!,
            status: QuoteStatus.DRAFT,
        },
        items: data.items.map((item) => ({
            ...item,
            covers: item.covers.map((cover) => ({
                ...cover,
                coverageAmount: cover.coverageAmount ?? 0,
            })),
            beneficiaries: item.beneficiaries.map((beneficiary) => ({
                ...beneficiary,
                distribution: beneficiary.distribution / 100,
                dateOfBirth: beneficiary.dateOfBirth
                    ? moment(beneficiary.dateOfBirth).format(DATE_SERVER_FORMAT)
                    : undefined,
            })),
            underwritingDetails: {
                productQuestionnaireIdentifier: sellerProduct.questionnaire!.uuid,
                answers: JSON.stringify(
                    Object.entries(item.questionnaire).reduce((a, [name, formDetail]) => {
                        let value = formDetail.value;
                        if (formDetail.type === 'date' && value) {
                            value = moment(value).format(DATE_SERVER_FORMAT);
                        }

                        return { ...a, [name]: value };
                    }, {})
                ),
            },
            type: ItemType.PERSON,
            itemCoverStartDate: item.itemCoverStartDate
                ? item.itemCoverStartDate
                : moment(data.effectiveDate).format(DATE_SERVER_FORMAT),
        })),
        reason: PolicyVersionReason.QUOTE,
        effectiveDate: moment(data.effectiveDate).format(DATE_SERVER_FORMAT),
        anniversaryDate: policy.currentPolicyVersion.anniversaryDate,
        endDate: policy.currentPolicyVersion.endDate,
        productIdentifier: sellerProduct.uuid,
    };
};

export enum ActionType {
    SEND_QUOTE,
    CREATE_ENDORSEMENT,
    SAVE_DRAFT,
}
